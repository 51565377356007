import { createRandomString } from 'core/auth/sso/utils/utils';
import { tlnBaseUrl, tlnClientId, tlnInstitutionId } from 'core/constants/app-settings';
import { appRouterUrl } from 'core/constants/router-url';
import { fetchData, postData } from "core/utils/http-utils";

function buildUrl(emplid: string) {
  const url = `/api/${emplid}/tln`;

  return url;
}

export type TlnAssociationPayload = {
  emplid?: string;
  tlnLearnerId: string;
  institutionId: string;
};

async function getTlnStatus(emplid: string) {
  const url = buildUrl(emplid);
  const { data } = await fetchData<boolean>(
    url
  );

  return data;
}

async function associateTlnIdpUser(payload: TlnAssociationPayload) {
  if(!payload.emplid || !payload.tlnLearnerId) {
    throw new Error('Missing URL parameters');
  }

  try {
    const url = buildUrl(payload.emplid) + `/${payload.tlnLearnerId}`;
    const { data } = await postData<API.BaseHttpResponse<null>>(url, {
      // TODO: Request may eventually have a body
    });
  } catch (error) {
    return false;
  }

  return true;
}

function buildTlnConsentUrl() {
  let returnUrl = 'https://' + window.location.host + appRouterUrl.TLN_RESPONSE;
  let tlnUrl = tlnBaseUrl;
  // clientId - a unique identifier provided by TLN specific to the IDP application
  tlnUrl += `?clientId=${tlnClientId}`;
  // redirectURL - an IDP URL that the TLN app will redirect the learn back to
  //  once the learner has consented to sharing credentials. This should
  //  be the TLN_RESPONSE page
  tlnUrl += `&redirectURL=${encodeURIComponent(returnUrl)}`;
  // state - A string value that would be returned as is in the URL by the TLN app.
  //   If the TLN app needs to know the originating institution & the learner’s
  //   institution id, this can be a base64 encoded JSON string containing
  //   relevant information. If extra information is not required, this can
  //   be a randomly generated string
  tlnUrl += `&state=${createRandomString()}`;
  tlnUrl += `&tln_institution_id=${tlnInstitutionId}`;

  return tlnUrl;
}

// function getMockTlnConsentUrl() {
//   // TODO: Function for testing TLN integration within IDP app
//   const tlnUsername = 'bmason26';
//   let baseUrl = '/tln/acknowledge';
//   baseUrl += `?tln_user_id=${tlnUsername}`;
//   baseUrl += `&state=${createRandomString()}`;
//   return baseUrl;
// }

export {
  associateTlnIdpUser,
  getTlnStatus,
  buildTlnConsentUrl,
};
