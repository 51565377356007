import { FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import { authClient } from 'core/auth';
import { logout } from 'core/auth/store';
import { CloseSVG, SearchSVG } from 'core/components/Icons';
import { MOBILE_BREAK_POINT } from 'core/constants/app-constants';
import { useAppDispatch } from 'core/store';

const Nav = styled.nav`
  min-height: 30px;
  height: fit-content;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  background-color: #e8e8e8;
  transition: all 0.5s cubic-bezier(0.19, 1, 0.19, 1) 0s;

  .links-container {
    display: flex;

    .nav-link {
      color: #484848;
      font-size: 0.75rem;
      line-height: 0.75rem;
      padding: 0.25rem 0.5rem;
      margin-right: 0.5rem;
      white-space: nowrap;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      &.sr-only:not(:focus):not(:active) {
        position: absolute;
        padding: 0;
        width: 1px;
        height: 1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border-width: 0;
      }

      &.sr-only-focusable:active,
      &.sr-only-focusable:focus {
        clip: auto;
        position: static;
        white-space: normal;

        overflow: visible;
        width: auto;
        height: auto;
        margin: 0;
      }
    }
  }

  @media (max-width: ${MOBILE_BREAK_POINT}) {
    #top-bar-user-name {
      display: none;
    }
  }
`;

const FormSearch = styled.form`
  isolation: isolate;

  justify-content: center;
  align-items: center;

  .search-button {
    border: none;
    background: transparent;
    cursor: pointer;
    line-height: 1;
    margin: 0.3rem;
  }

  [data-search-open='true'] & .search-button {
    pointer-events: none;
    z-index: 1;
  }

  [data-search-open='false'] & {
    .search-input,
    .search-close {
      display: none;
    }
  }

  .search-input {
    width: 200px;
    background-color: rgb(255, 255, 255);
    border-radius: 0px;
    padding: 0.5rem 0.5rem 0.5rem 2rem;
    font-weight: 400;
    line-height: 1.5;
    color: rgb(73, 80, 87);
    border: 1px solid rgb(112, 112, 112);
    margin: 0.5rem 0px;
    display: unset;
    margin-left: -2rem;
  }

  .search-close {
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(208, 208, 208);
    border-radius: 100%;
    padding: 0.45rem 0.75rem;
    margin-inline: 0.45rem;
    height: fit-content;
  }
`;

const Button = styled.button`
  border: 0;
  background: transparent;
`;

type TopNavbarProps = {
  id?: string;
  className?: string;
  userName: string;
};

const searchInput = () =>
  document.querySelector<HTMLInputElement>('#search-input');

const TopNavbar: FC<TopNavbarProps> = ({ id, className, userName }) => {
  const dispatch = useAppDispatch();
  const [searchToggle, setSearchToggle] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  let clientInstance = authClient.getClientInstance();

  const handleSignOut = useCallback(() => {
    clientInstance?.logout();
    dispatch(logout());
  }, [clientInstance, dispatch]);

  return (
    <Nav
      id={id}
      className={className}
      aria-label="ASU"
      data-search-open={searchToggle}
    >
      <div className="links-container">
        <a
          className="nav-link sr-only-focusable sr-only"
          href={`https://accessibility.asu.edu/report?a11yref=${window.location.href}`}
        >
          Report an accessibility problem
        </a>
        <a
          className="nav-link sr-only-focusable sr-only"
          href="#skip-to-content"
        >
          Skip to main content
        </a>

        <Button className='sign-out nav-link' onClick={handleSignOut}>
          <strong className="name me-2" id="top-bar-user-name">
            {userName}
          </strong>
          (Sign Out)
        </Button>
      </div>
      <FormSearch
        className="flex"
        action="https://search.asu.edu/search"
        method="get"
        name="gs"
      >
        <button
          type="button"
          aria-label="Search asu.edu"
          className="search-button"
          disabled={searchToggle}
          onClick={() => {
            setSearchToggle(true);
            setTimeout(() => {
              searchInput()?.focus();
            }, 10);
          }}
        >
          <SearchSVG />
        </button>

        <input
          id="search-input"
          className="form-control search-input"
          type="search"
          name="q"
          aria-labelledby="header-top-search"
          placeholder="Search asu.edu"
          required
          value={searchValue}
          onChange={(e) => setSearchValue(e.currentTarget.value)}
        />

        <button
          type="button"
          aria-label="Search asu.edu"
          className="search-close"
          onClick={() => {
            setSearchToggle(false);
            setSearchValue('');
          }}
        >
          <CloseSVG />
        </button>

        <input name="url_host" type="hidden" value="" />
        <input name="site" type="hidden" value="default_collection" />
        <input name="sort" type="hidden" value="date:D:L:d1" />
        <input name="output" type="hidden" value="xml_no_dtd" />
        <input name="ie" type="hidden" value="UTF-8" />
        <input name="oe" type="hidden" value="UTF-8" />
        <input name="client" type="hidden" value="asu_frontend" />
        <input name="proxystylesheet" type="hidden" value="asu_frontend" />
      </FormSearch>
    </Nav>
  );
};

export { TopNavbar };
