// @ts-check
const CODE_RE = /[?&]code=[^&]+/;
const STATE_RE = /[?&]state=[^&]+/;
const ERROR_RE = /[?&]error=[^&]+/;

export const hasAuthParams = (searchParams = window.location.search) =>
  (CODE_RE.test(searchParams) || ERROR_RE.test(searchParams)) &&
  STATE_RE.test(searchParams);

export const encode = (value = '') => btoa(value);

const getCrypto = () => {
  //ie 11.x uses msCrypto
  return window.crypto || window.msCrypto;
};

export const createRandomString = () => {
  const charset =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-_~.';
  let random = '';
  const randomValues = Array.from(
    getCrypto().getRandomValues(new Uint8Array(43)),
  );
  randomValues.forEach((v) => (random += charset[v % charset.length]));
  return random;
};

export const sha256 = async (s = '') => {
  const digestOp = getCryptoSubtle().digest(
    { name: 'SHA-256' },
    new TextEncoder().encode(s),
  );

  // msCrypto (IE11) uses the old spec, which is not Promise based
  // https://msdn.microsoft.com/en-us/expression/dn904640(v=vs.71)
  // Instead of returning a promise, it returns a CryptoOperation
  // with a result property in it.
  // As a result, the various events need to be handled in the event that we're
  // working in IE11 (hence the msCrypto check). These events just call resolve
  // or reject depending on their intention.
  if (window.msCrypto) {
    return new Promise((res, rej) => {
      // @ts-ignore
      digestOp.oncomplete = (e) => {
        res(e.target.result);
      };

      // @ts-ignore
      digestOp.onerror = (e) => {
        rej(e.error);
      };

      // @ts-ignore
      digestOp.onabort = () => {
        rej('The digest operation was aborted');
      };
    });
  }

  return await digestOp;
};

export const getCryptoSubtle = () => {
  const crypto = getCrypto();
  //safari 10.x uses webkitSubtle
  return crypto.subtle || crypto.webkitSubtle;
};

export const bufferToBase64UrlEncoded = (input = 0) => {
  const ie11SafeInput = new Uint8Array(input);
  return urlEncodeB64(
    window.btoa(String.fromCharCode(...Array.from(ie11SafeInput))),
  );
};

const urlEncodeB64 = (input = '') => {
  const b64Chars = {
    '+': '-',
    '/': '_',
    '=': '',
  };
  return input.replace(
    /[+/=]/g,
    (m) => b64Chars[/** @type {"+" | "/" | "="} */ (m)],
  );
};

export const parseQueryResult = (queryString = '') => {
  if (queryString.indexOf('#') > -1) {
    queryString = queryString.substr(0, queryString.indexOf('#'));
  }

  const queryParams = queryString.split('&');

  const parsedQuery = /** @type {any} */ ({});
  queryParams.forEach((qp) => {
    const [key, val] = qp.split('=');
    parsedQuery[key] = decodeURIComponent(val);
  });

  return {
    ...parsedQuery,
  };
};
