import { FC, useEffect, useRef } from 'react';
import { createPieChart } from '../helpers/d3/create-pie-chart';
import '../helpers/d3/create-pie-chart.css';

const defaultAreaColor1 = 'var(--green)';
const defaultAreaColor2 = 'var(--gray-2)';

type CirclePathPieChartProps = {
  workDonePercent: number;
  workLeftPercent: number;
  areaTitle1?: string;
  areaTitle2?: string;
  width?: number;
  height?: number;
  areaColor1?: string;
  areaColor2?: string;
  showTooltip?: boolean;
};

const CirclePathPieChart: FC<CirclePathPieChartProps> = ({
  workDonePercent = 0,
  workLeftPercent = 0,
  areaColor1 = defaultAreaColor1,
  areaColor2 = defaultAreaColor2,
  areaTitle1 = `Total accomplished hours:<br>
  (In-progress hours +<br>
    Earned hours +<br>
    Remaining hours)`,
  areaTitle2 = `Total remaining hours`,
  width = 240,
  height = 240,
  showTooltip = true,
}) => {
  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    const svgElement = svgRef.current;

    if (svgElement) {
      const areaWorkDone = {
        label: areaTitle1,
        percent: workDonePercent,
        color: areaColor1,
      };
      const areaWorkInLeft = {
        label: areaTitle2,
        percent: workLeftPercent,
        color: areaColor2,
      };
      const dataset: ProgressChartDataset = [areaWorkDone, areaWorkInLeft];

      createPieChart({
        svgElement,
        dataset,
        progressDone: workDonePercent,
        width,
        height,
        showTooltip,
      });
    }

    return () => {
      while (svgElement?.firstChild) {
        svgElement.firstChild.remove();
      }
    };
  }, [
    areaColor1,
    areaColor2,
    areaTitle1,
    areaTitle2,
    height,
    width,
    showTooltip,
    workDonePercent,
    workLeftPercent,
  ]);

  return (
    <svg ref={svgRef} data-type="pie-chart" width={width} height={height} />
  );
};

export { CirclePathPieChart, defaultAreaColor1, defaultAreaColor2 };
