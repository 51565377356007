import styled from 'styled-components';

interface ChipProps {
  type?: 'button-chip';
  bold?: boolean;
  fontSize?: React.CSSProperties['fontSize'];
  hasBorder?: boolean;
}

const Chip = styled.span.attrs<ChipProps>(
  ({ fontSize = '0.875rem', type, bold = false }) => ({
    className: 'chip',
    role: 'button',
    'data-type': type,
    'data-bold': bold,
    fontSize,
  }),
)<ChipProps>`
  height: fit-content;
  background: var(--gray-3) 0% 0% no-repeat padding-box;
  background: #d0d0d0 0% 0% no-repeat padding-box;

  border-radius: 4px;
  padding: 2px 4px;

  display: inline-flex;
  align-items: center;
  gap: 0.5rem;

  font-size: ${(props) => props.fontSize};
  letter-spacing: 0px;
  color: var(--gray-7);

  &[data-bold='true'] {
    font-weight: bold;
  }

  &[data-type='button-chip'] {
    cursor: pointer;
    background: transparent;

    border: 1px solid var(--gray-5);
    border-radius: 25px;
    padding: 0.5rem 1rem;

    font-size: 0.875rem;
    font-weight: bold;
    line-height: 1;
  }
`;

export { Chip };
