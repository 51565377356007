import styled from 'styled-components';

type ListProps = {};

const List = styled.ul.attrs<ListProps>(() => ({}))<ListProps>`
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    padding: 0;
  }
`;

export { List };
