import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

// core
import { Box, Button } from 'core/components';
import { appRouterUrl } from 'core/constants/router-url';
import { useScrollTop } from 'core/hooks/use-scroll-top';
import { buildTlnConsentUrl } from 'core/services/tln-service';
import { useAppDispatch } from 'core/store';
import { dismissTlnRequest } from 'core/store/slices/userTlnConsentSlice';
import tlnLogo from 'img/tln/tln-logo.svg';


const Container = styled.section`
  --margin-offset: 3rem;

  h3 {
    margin: 0;
  }

  p {
    font-size: 0.875rem;
    line-height: 1rem;
    letter-spacing: 0px;
    color: #484848;
  }

  max-height: 940px;
  min-width: 450px;
  width: auto;
  max-width: 750px;

  margin-inline: auto;
  margin-block: var(--margin-offset);
`;

const BoxContainer = styled(Box)`
  border-radius: 0;
  padding: 1rem 2rem;
`;

const BoxFooter = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const TileContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

const Tile = styled.div`
  border: 2px solid var(--gray-3);
  border-radius: 0.5rem;
  padding: 1rem 1rem;
  margin-right: 1rem;
  width: 50%;
  position: relative;

  &:last-child {
    margin-right: 0;
  }

  button { margin-top: 1rem; }

  &.recommended {
    border-color: var(--primary);

    &::after {
      --badge-size: 25px;
      content: 'Recommended';
      color: var(--white);
      background-color: var(--primary);
      position: absolute;
      top: calc(-1 * ((var(--badge-size) + 0.25rem*2) / 2));
      left: 1rem;
      border-radius: 0.5rem;

      font-size: 0.9rem;
      // line-height: var(--badge-size);
      padding: 0.25rem 0.75rem;
    }
  }
`;

const InlineButton = styled(Button)`
  display: inline-grid;
`;

const InlineTextButton = styled(InlineButton)`
  text-decoration: underline;
  border: 0;
  padding: 0 !important;
  margin: 1.5rem 0;
  font-weight: normal;
`;

const TlnRequest: FC<{}> = () => {
  const [isInitialized, setIsInitialized] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useScrollTop();

  const requestTlnConsent = useCallback(() => {
      // Navigate to TLN
      let tlnUrl = buildTlnConsentUrl();
      window.location.href = tlnUrl;
  }, []);

  const dismissTlnConsent = useCallback(() => {
    if (isInitialized) {
      dispatch(dismissTlnRequest());
      navigate(appRouterUrl.STUDENT_DASHBOARD);
    }
  }, [isInitialized, dispatch, navigate]);

  useEffect(() => {
    setIsInitialized(true);
  }, [dispatch]);

  return (
    <Container>
      <BoxContainer>
        <h2>Import transcripts and test scores</h2>
        <p>
          For the most accurate degree plans, we recommend that you import your
          previous academic records to indicate the course credits you have
          already earned. This will impact your results when calculating your
          current progress toward any degree.
        </p>

        <TileContainer>
          <Tile className='recommended'>
            <img
              className="max-h-8rem h-auto w-full"
              src={tlnLogo}
              alt="The Learner Network (TLN)"
            />
            <h4>Sync with Trusted Learner Network (TLN)</h4>
            <p>
              Sign in or create an account with TLN to quickly import your
              academic records
            </p>
            <InlineButton onClick={requestTlnConsent} buttonType='primary'>
              Sync with TLN
            </InlineButton>
          </Tile>
          {/* <Tile>
            <img
              className="max-h-8rem h-auto w-full"
              src={''}
              alt="Enter records manually"
            />
            <h4>Enter records manually</h4>
            <p>
              Upload your records as PDF files and manually confirm that the
              information is correct
            </p>
            <InlineButton onClick={dismissTlnConsent}>
              Enter manually
            </InlineButton>
          </Tile> */}
        </TileContainer>

        <BoxFooter>
          <InlineTextButton onClick={dismissTlnConsent} size='md'>
            I'll do this later
          </InlineTextButton>
        </BoxFooter>
      </BoxContainer>
    </Container>
  );
};

export { TlnRequest };
export default TlnRequest;
