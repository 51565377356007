import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Footer, Navbar } from 'core/components';

type PageLayoutProps = {};

const PageLayout: FC<PageLayoutProps> = () => {
  return (
    <>
      <Navbar />
      <main className="app">
        <Outlet />
      </main>
      {/* <Footer /> */}
    </>
  );
};

export { PageLayout };
