import { FC } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  .checked-icon,
  .not-checked-icon {
    display: none;
  }

  &[data-active='true'] {
    .checked-icon {
      display: block;
    }
  }

  &[data-active='false'] {
    .not-checked-icon {
      display: block;
    }
  }

  width: fit-content;
  display: flex;

  padding: 0.2rem 0.5rem;
  background-color: var(--gray-3);
  background-clip: padding-box;
  border-radius: 4px;

  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1;
  letter-spacing: -0.22px;
  color: var(--gray-6);

  span {
    margin-inline-start: 0.5rem;
    display: inline-block;
  }
`;

type StarChipProps = {
  className?: string;
  label: string;
  active?: boolean;
};

const StarChip: FC<StarChipProps> = ({ className, label, active = false }) => {
  return (
    <Container className={className} data-active={active}>
      <i className="fa-regular fa-star not-checked-icon" />
      <i className="fa-solid fa-star checked-icon" />
      <span>{label}</span>
    </Container>
  );
};

export { StarChip };
