import { FC } from 'react';
import { BaseButton } from '../BaseButton';

type TrashButtonProps = {
  onClick?: () => void;
};

const TrashButton: FC<TrashButtonProps> = ({ onClick }) => {
  return (
    <BaseButton onClick={onClick}>
      <i className="fa-solid fa-trash" />
    </BaseButton>
  );
};

export { TrashButton };
