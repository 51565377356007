import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { savePlan, updatePlan } from 'core/services/plan-service';
import { cloneObject } from 'core/utils/structured-clone-utils';
import { setHttpError } from './sharedSlice';
import { pickEmplid } from './utils';

const initialStateValidator: PlanSetupState['validator'] = {
  canValidate: false,
  isValid: false,
  fields: {
    planName: false,
    configMethod: false,
  },
};

const initialState: PlanSetupState = {
  saving: false,
  saved: false,
  data: {
    planName: '',
    configMethod: 'graduation-term',
    includeSummerTerms: false,
    graduationTerm: '',
    creditHourTerm: '',
  },
  dataAuditChanged: false,
  dataChanged: false,
  validator: initialStateValidator,
  formData: {
    planName: '',
    configMethod: 'graduation-term',
    includeSummerTerms: false,
    graduationTerm: '',
    creditHourTerm: '',
  },
};

function getDefaultFormData() {
  return cloneObject(initialState.formData) as API.PlanData.Setup;
}

function hasFormChanged(state: PlanSetupState): boolean {
  let dataChanged = false;

  for (const key of Object.keys(state.formData)) {
    const name = key as keyof typeof state.formData;

    if (state.data[name] !== state.formData[name]) {
      dataChanged = true;
      break;
    }
  }

  return dataChanged;
}

export const planSetupSlice = createSlice({
  name: 'planSetup',
  initialState,
  reducers: {
    setPlanSaving: (state, action: ActionOf<boolean>) => {
      state.saving = action.payload;
    },
    setCanValidate: (state, action: ActionOf<boolean>) => {
      state.validator.canValidate = action.payload;
    },
    resetValidator: (state) => {
      state.validator = initialStateValidator;
    },
    setValid: (state, action: ActionOf<boolean>) => {
      state.validator.isValid = action.payload;
    },
    setPlanValidator: (state) => {
      if (Object.keys(state.formData).length === 0) return;

      const { planName, configMethod, graduationTerm, creditHourTerm } =
        state.formData;

      const validationFields = state.validator.fields;

      if (configMethod === 'graduation-term' && !graduationTerm) {
        validationFields.configMethod = false;
      } else if (configMethod === 'credit-hour-term' && !creditHourTerm) {
        validationFields.configMethod = false;
      } else {
        validationFields.configMethod = true;
      }

      if (planName?.trim()) {
        validationFields.planName = true;
      } else {
        validationFields.planName = false;
      }

      state.validator.isValid = Object.values(state.validator.fields).every(
        (value) => value === true,
      );
    },
    setPlanForm: (state, action: ActionOf<API.PlanData.Setup>) => {
      state.formData = action.payload;
    },
    setPlanField: (state, action: ActionOf<Partial<API.PlanData.Setup>>) => {
      state.formData = {
        ...state.formData,
        ...action.payload,
      };
      state.dataChanged = hasFormChanged(state);

      if (!action.payload.planName) {
        state.dataAuditChanged = true;
      }
    },
    resetPlanForm: (state) => {
      state.formData = {
        ...getDefaultFormData(),
      };
    },
    resetPlanValidator: (state) => {
      state.validator = { ...initialStateValidator };
    },
    setPlanSetup: (state, action: ActionOf<API.PlanData.Setup>) => {
      state.data = action.payload;
      state.formData = {
        ...initialState.formData,
        ...state.data,
      };
    },
  },
});

export const savePlanAsync = createAsyncThunk(
  'planSetupSlice/savePlanAsync',
  async (payload: API.PlanData.Setup, { dispatch, getState }) => {
    dispatch(setPlanSaving(true));
    try {
      const emplid = pickEmplid(getState());
      const planData = {
        ...payload,
        planName: payload.planName.trim(),
      };
      let planId: string;

      if (payload.id) {
        const data = await updatePlan(emplid, planData);
        planId = data.uuid;
      } else {
        const data = await savePlan(emplid, planData);
        planId = data.id as string;
      }

      return { planId, saved: true };
    } catch (error) {
      dispatch(
        setHttpError({
          httpError: (error as HTTPError).message,
          sourceAction: savePlanAsync.typePrefix,
        }),
      );
      return { error, saved: false };
    } finally {
      dispatch(setPlanSaving(false));
    }
  },
);

export const {
  setPlanSaving,
  setPlanSetup,
  setPlanForm,
  setPlanField,
  resetPlanForm,
  resetPlanValidator,
  setPlanValidator,
  setCanValidate,
  resetValidator,
  setValid,
} = planSetupSlice.actions;

export default planSetupSlice.reducer;
