import { useEffect } from 'react';
import { getOperativeSystemName } from 'core/utils/get-user-os';
import { getScrollbarWidth } from 'core/utils/html-utils';

let countComputation = 0;

function checkScrollbarSettings() {
  if (document.visibilityState === 'visible') {
    const scrollbarWidth = getScrollbarWidth();
    document.body.setAttribute('data-scrollbar-width', String(scrollbarWidth));

    document.body.setAttribute(
      'data-scrollbar-always-visible',
      String(scrollbarWidth > 0),
    );

    countComputation++;
    console.log(`[Scrollbar Check][Start] Counter is ${countComputation}`);
    // Arbitrary number to improve performance.
    // This give the capability to change setting on the fly 10 times
    // Then the user needs to refresh the page to get the changes
    if (countComputation === 10) {
      stopCheckScrollbarSettings();
    }
  }
}

function stopCheckScrollbarSettings() {
  document.removeEventListener('visibilitychange', checkScrollbarSettings);
  console.log(`[Scrollbar Check][Stop]`);
}

function useScrollbarSettings() {
  useEffect(() => {
    const osName = getOperativeSystemName();
    document.body.setAttribute('data-os-name', osName);
  }, []);

  useEffect(() => {
    document.addEventListener('visibilitychange', checkScrollbarSettings);
    checkScrollbarSettings();

    return () => {
      stopCheckScrollbarSettings();
    };
  }, []);
}

export { useScrollbarSettings };
