import React, { FC } from 'react';

import styled from 'styled-components';
import { ErrorMessage } from '../ErrorMessage';

const InputTime = styled.input`
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  font-size: 0.875rem;
`;

type TimeFieldProps = GenericFieldProps & {
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
};

const TimeField: FC<TimeFieldProps> = ({
  className,
  label,
  value,
  placeholder,
  error,
  style = {},
  onChange,
}) => {
  return (
    <div className={'form-group ' + className} style={style}>
      <label>{label}</label>
      <InputTime
        type="time"
        className="form-control"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </div>
  );
};

export { TimeField };
