import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { associateTlnIdpUser, getTlnStatus, TlnAssociationPayload } from 'core/services/tln-service';
import { setHttpError } from './sharedSlice';
import { pickEmplid } from './utils';

const initialState: userTlnConsentState = {
  tlnLoading: false,
  tlnUserId: undefined,
  InstitutionName: '',
  learnerId: undefined,
  isTlnUser: false,
  dataSyncConsent: false,
  bannerDismissed: false,
  requestDismissed: false,
};

export const userTlnConsentSlice = createSlice({
  name: 'userTlnConsent',
  initialState,
  reducers: {
    setTlnLoading: (state, action: ActionOf<boolean>) => {
      state.tlnLoading = action.payload;
    },
    setTlnUserId: (state, action: ActionOf<string>) => {
      state.tlnUserId = action.payload;
    },
    setInstitutionName: (state, action: ActionOf<string>) => {
      state.InstitutionName = action.payload;
    },
    setInstitutionUserId: (state, action: ActionOf<string>) => {
      state.learnerId = action.payload;
    },
    setIsTlnUser: (state, action: ActionOf<boolean>) => {
      state.isTlnUser = action.payload;
    },
    setTlnDataSyncConsent: (state, action: ActionOf<boolean>) => {
      state.dataSyncConsent = action.payload;
    },
    dismissTlnBanner: (state) => {
      state.bannerDismissed = true;
    },
    dismissTlnRequest: (state) => {
      state.requestDismissed = true;
    },
  },
});

export const refreshUserTlnStatus = createAsyncThunk(
  'userTlnConsentSlice/refreshUserTlnStatus',
  async (_, { dispatch, getState }) => {
    dispatch(setTlnLoading(true));
    try {
      const currentUserEmplid = pickEmplid(getState());
      const tlnIdIsValid = await getTlnStatus(currentUserEmplid);

      dispatch(setIsTlnUser(tlnIdIsValid));
      return tlnIdIsValid;
    } catch (error) {
      dispatch(
        setHttpError({
          httpError: (error as HTTPError).message,
          sourceAction: refreshUserTlnStatus.typePrefix,
        }),
      );
      return { error, saved: false };
    } finally {
      dispatch(setTlnLoading(false));
    }
  },
);

export const associateUserToTln = createAsyncThunk(
  'userTlnConsentSlice/associateUserToTln',
  async (payload: any, { dispatch, getState }) => {
    dispatch(setTlnLoading(true));
    try {
      const state = getState() as AppState;
      const currentUserEmplid = pickEmplid(state);

      const data = associateTlnIdpUser({
        ...payload,
        tlnLearnerId: state.userTlnConsent.tlnUserId || '',
        emplid: currentUserEmplid,
      });

      return data;
    } catch (error) {
      dispatch(
        setHttpError({
          httpError: (error as HTTPError).message,
          sourceAction: associateUserToTln.typePrefix,
        }),
      );
      return { error, saved: false };
    } finally {
      dispatch(setTlnLoading(false));
    }
  },
);

export const {
  setTlnLoading,
  setTlnUserId,
  setInstitutionName,
  setInstitutionUserId,
  setIsTlnUser,
  setTlnDataSyncConsent,
  dismissTlnBanner,
  dismissTlnRequest,
} = userTlnConsentSlice.actions;

export default userTlnConsentSlice.reducer;
