import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';

import { AuthProvider } from 'core/auth';
import { SpinnerLoader } from 'core/components';
import { BrandingOverrideProvider } from 'core/components/Branding/BrandingOverrideProvider';
import { authSettings } from 'core/constants/auth-settings';
import { AppProvider } from 'core/context/app-context';
import { getUserRoles } from 'core/services/user-service';
import { store } from 'core/store';
import { formatLog } from 'core/utils/logger-utils';
import App from './App';
import './index.scss';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
// =======================================================================
// DEV mode - Component mounted twice
// const StrictMode = React.StrictMode
// =======================================================================
// PROD mode - Component mounted once
// =======================================================================
// Use this wrapper to test it as in prod mode. Why? Use of `localStorage`
// might give misleading results after the second component mount
const StrictMode = React.Fragment;
// =======================================================================

root.render(
  <StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <AuthProvider
          authProps={{
            customSettings: authSettings,
            Loader: ({ message }) => (
              <SpinnerLoader
                spinnerMessage={message}
                spinnerType="circle"
                width="20px"
                height="20px"
                fullSize
              />
            ),
            getUserRoles: async () => {
              const data = await getUserRoles();
              console.log(
                ...formatLog({
                  action: 'GET-ROLES',
                  message: `Get IDP roles`,
                }),
              );
              return data.roles;
            },
          }}
        >
          <AppProvider>
            <>
              <BrandingOverrideProvider/>
              <App />
            </>
          </AppProvider>
        </AuthProvider>
      </Provider>
    </BrowserRouter>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
