import { ElementRef, FC, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { useAuthContext } from 'core/auth';
import { MOBILE_BREAK_POINT, RoleName } from 'core/constants/app-constants';
import { appRouterUrl as urls } from 'core/constants/router-url';
import { useMedia } from 'core/hooks/use-media';
import {
  useAppDispatch,
  useAppSelector,
  useCurrentRoleSelector,
} from 'core/store';
import { setSelectedRoute } from 'core/store/slices';
import asuLogoData from 'img/asu-logo/asu-sunburst-maroon-gold.png';
import partnerConfig from '../../../partner-config.json';

import { ProfileMenu } from '../ProfileMenu';
import { NavbarLogo } from './components/NavbarLogo';
import { TopNavbar } from './components/TopNavbar';

type NavbarProps = {};

function computeNavbarHeight(): string {
  const topNavbar = document.querySelector<HTMLElement>('#top-navbar');
  const headerContainer = document.querySelector(
    '#header-container',
  ) as HTMLElement;

  if (!headerContainer) {
    console.warn('Navbar not found');
    return '0';
  }
  // set header scrolling margin
  const maxNavHeight =
    headerContainer.clientHeight + (topNavbar?.clientHeight || 0) + 'px';
  headerContainer.setAttribute('data-max-height', maxNavHeight);

  return maxNavHeight;
}

function computeMarginOffset() {
  const currentNavbarHeight = computeNavbarHeight();
  document.documentElement.style.setProperty(
    '--navbar-height',
    currentNavbarHeight,
  );
}

function storeInitialNavbarHeight() {
  const currentNavbarHeight = computeNavbarHeight();
  document.documentElement.style.setProperty(
    '--initial-navbar-height',
    currentNavbarHeight,
  );
}

const Header = styled.header`
  --timing-function: cubic-bezier(0.19, 1, 0.19, 1);
  &[data-show-navbar='false'] {
    width: fit-content;
    right: 0px;
    left: 100%;
    margin-bottom: -65px;

    [data-id='navbar-expanded'] {
      visibility: hidden;
      height: 0;
      width: 0;
      display: none;
    }
  }
`;

const Nav = styled.nav`
  background: #ffffff 0% 0% no-repeat padding-box;
  border-bottom: 1px solid #bfbfbf;

  /* #region  nav item selection */
  --link-hover-width: 100%;
  --link-hover-left: 0;

  &::before,
  &::after {
    content: '';
    position: absolute;
    border-bottom: 4px solid;
    height: 0;
    bottom: -1px;
    opacity: 0;
    transition-timing-function: var(----timing-function);
    transition-duration: 0.2s;
  }

  &::before {
    width: var(--link-hover-width);
    left: var(--link-hover-left);
    border-bottom-color: var(--gray-4);
    transition-property: opacity;
  }

  &::after {
    width: var(--link-active-width);
    left: 0;
    transform: translateX(var(--link-active-left));
    border-bottom-color: var(--primary);
    transition-property: transform;
  }

  &[data-link-hover='true']::before,
  &[data-link-active='true']::after {
    transition-duration: 0.5s;
    opacity: 1;
  }
  /* #endregion nav item selection */
`;

const NavListContainer = styled.div``;

const NavList = styled.ul`
  list-style: none;
  opacity: 1;
  transition: opacity 0.7s var(----timing-function);
  &[data-items='0'] {
    opacity: 0;
  }
`;

const NavItem = styled.li`
  a {
    text-decoration: none;
    display: inline-block;
    line-height: 1;
    color: var(--link-color);
    transition: all 0.5s var(----timing-function);

    &:hover {
      color: var(--link-color-hover);
    }
  }

  &[data-selected='true'] {
    a {
      font-weight: bold;
    }
  }
`;

const Title = styled.h1`
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.6px;
  transition: all 1.5s var(----timing-function) 0s;

  & a {
    transition: inherit;
    color: var(--gray-7);
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: ${MOBILE_BREAK_POINT}) {
    & {
      display: none;
    }
  }
`;

//#region "Navtree role based"
function buildNavbar(role: UserProfileRole, selectedRoute: string) {
  const buildRoute = (id: string, routePath: string, text: string) => ({
    id,
    text,
    routePath,
    selected: selectedRoute === routePath,
  });

  const roleRouteLinks: Record<string, () => ReturnType<typeof buildRoute>[]> =
    {
      [RoleName.ADMIN]: () => {
        return [
          buildRoute('ad-dashboard', urls.ADMIN_DASHBOARD, 'Dashboard'),
          buildRoute('ad-settings', urls.ADMIN_SETTINGS, 'Settings'),
          buildRoute('ad-reports', urls.ADMIN_REPORTS, 'Reports'),
        ];
      },
      [RoleName.ADVISOR]: () => {
        return [buildRoute('adv-dashboard', urls.ADMIN_DASHBOARD, 'Dashboard')];
      },
      [RoleName.STUDENT]: () => {
        return [
          buildRoute('st-dashboard', urls.STUDENT_DASHBOARD, 'Dashboard'),
          buildRoute('st-plans', urls.PLANS_LIST, 'My plans'),
          buildRoute('st-compare', urls.COMPARISON_LIST, 'My comparison'),
        ];
      },
    };

  roleRouteLinks[RoleName.COACH] = roleRouteLinks[RoleName.ADVISOR];
  roleRouteLinks[RoleName.PLATFORM] = roleRouteLinks[RoleName.STUDENT];

  const navTree = roleRouteLinks[role]();

  return navTree;
}
//#endregion

function setNavStyle(
  navbar: HTMLElement,
  target: Element,
  prop: 'hover' | 'active',
) {
  const { width, left } = target.getBoundingClientRect();

  navbar.style.setProperty(`--link-${prop}-width`, `${width}px`);
  navbar.style.setProperty(`--link-${prop}-left`, `${left}px`);
}

const Navbar: FC<NavbarProps> = () => {
  const dispatch = useAppDispatch();
  const {
    state: { user, returnUrl },
  } = useAuthContext();
  const { isAuthenticated } = useAppSelector(
    (state) => state.auth,
  );
  const showNavbar = useAppSelector((state) => state.shared.showNavbar);
  const selectedRoute = useAppSelector(
    (state) => state.shared.selectedRoute || '',
  );
  const currentRole = useCurrentRoleSelector();

  const isMobile = useMedia(`(max-width: ${MOBILE_BREAK_POINT})`);

  // #region Navbar creation
  const navRef = useRef<ElementRef<'nav'>>(null);

  const navTree = useMemo(() => {
    return isAuthenticated && currentRole
      ? buildNavbar(currentRole, selectedRoute)
      : []
  }, [isAuthenticated, currentRole, selectedRoute]);


  const selectedNavItem = navTree.find(
    (node) => node.routePath === selectedRoute,
  );
  // #endregion

  const [keydown, setKeydown] = useState(false);

  const focusLink = async ({ currentTarget }: { currentTarget: Element }) => {
    await unFocusLink();
    setNavStyle(navRef.current!, currentTarget, 'hover');
    navRef.current!.setAttribute('data-link-hover', 'true');
  };

  const unFocusLink = async () => {
    navRef.current!.removeAttribute('data-link-hover');
  };

  useEffect(() => {
    storeInitialNavbarHeight();
  }, []);

  const logoSrcData = useMemo(() => {
    if ((partnerConfig as any)?.logo_2) {
      return (partnerConfig as any)?.logo_2;
    } else {
      return asuLogoData;
    }
  }, []);

  useEffect(() => {
    const asuHeader = document.querySelector(
      '#header-container',
    ) as HTMLElement;
    const resizeObserver = new ResizeObserver(computeMarginOffset);
    resizeObserver.observe(asuHeader);

    return () => resizeObserver.disconnect();
  }, []);

  useEffect(() => {
    returnUrl && dispatch(setSelectedRoute(returnUrl));
  }, [dispatch, returnUrl]);

  useEffect(() => {
    if (selectedNavItem) {
      const activateLink = async () => {
        await unFocusLink();
        const navLink = navRef.current!.querySelector(`#${selectedNavItem.id}`);
        setNavStyle(navRef.current!, navLink!, 'active');
      };
      activateLink();
    }
  }, [selectedNavItem, selectedRoute, isMobile]);

  return (
    <>
      <TopNavbar id="top-navbar" className="z-100" userName={user || ''} />
      <Header
        id="header-container"
        className="z-100 sticky inset-x-0 top-0"
        data-show-navbar={showNavbar}
        onKeyDown={(e) => setKeydown(true)}
        onMouseDown={(e) => setKeydown(false)}
      >
        <Nav
          ref={navRef}
          data-link-active={navTree.some((node) => node.selected)}
          data-id="navbar-expanded"
          className="relative flex items-center p-4"
        >
          <Link to={urls.HOME} className="leading-none">
            <NavbarLogo sourceData={logoSrcData} />
          </Link>

          <Title className="my-0 me-7 font-bold">
            <Link to={urls.HOME}>Interactive Degree Planner</Link>
          </Title>

          <NavListContainer className="relative flex flex-grow items-center justify-between">
            <NavList className="m-0 flex gap-6 p-0" data-items={navTree.length}>
              {navTree.map((node) => (
                <NavItem
                  key={node.id}
                  id={node.id}
                  data-selected={node.selected}
                >
                  <Link
                    className={!keydown ? 'no-active-focus' : undefined}
                    to={node.routePath}
                    onFocus={focusLink}
                    onBlur={unFocusLink}
                    onMouseOver={focusLink}
                    onMouseLeave={unFocusLink}
                  >
                    {node.text}
                  </Link>
                </NavItem>
              ))}
            </NavList>

            <NavList className="m-0 flex p-0">
              <NavItem className="m-inline-3">
                <a href="/">Support</a>
              </NavItem>
              <NavItem className="flex">
                <ProfileMenu />
              </NavItem>
            </NavList>
          </NavListContainer>
        </Nav>
      </Header>
    </>
  );
};

export { Navbar };
