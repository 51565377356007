import { HttpCode } from 'core/constants/app-constants';
import { formatLog } from 'core/utils/logger-utils';
import { maskDemoUsersResponse } from 'dev/demo-service';
import { deleteData, fetchData, postData, putData } from '../utils/http-utils';
import { preProcessPlanForUI } from './plan-ui-data-service';

function buildUrl(emplid: string, planId?: string) {
  const planIdResource = planId ? `/${planId.replaceAll('PLAN#', '')}` : '';
  const url = `/api/${emplid}/plans${planIdResource}`;

  return url;
}

function sortPlanByDate(planList: API.PlanData.PlanRecord[]) {
  /** Arbitrary date to move those records without a TimeStamp down the list */
  const defaultTimeStamp = new Date(2022, 11, 1).toISOString();

  const sortedPlanList = [...planList].sort(
    (a, b) =>
      Date.parse(b.updatedTimestamp || defaultTimeStamp) -
      Date.parse(a.updatedTimestamp || defaultTimeStamp),
  );

  return sortedPlanList;
}

async function getActivePlan(emplid: string) {
  const url = `${buildUrl(emplid)}`;
  const { data } = await fetchData<API.HttpPlanActiveGetResponse>(
    `${url}/active`,
  );
  // TODO: tech debt: the API should return the marshalled object and not the record
  const dataPlan = await getPlan(emplid, data.id);

  return {
    record: data as API.PlanData.PlanRecord,
    plan: dataPlan.plan as API.PlanData.Plan,
  };
}

async function updateActivePlan(emplid: string, planId: string) {
  const url = `${buildUrl(emplid)}/active`;
  const { data } = await putData<API.HttpPlanActivePutResponse>(url, {
    id: planId,
  });
  // TODO: tech debt: the API should return the marshalled object and not the record
  const dataPlan = await getPlan(emplid, data.id);

  return {
    record: data as API.PlanData.PlanRecord,
    plan: dataPlan.plan as API.PlanData.Plan,
  };
}

async function getPlanList(emplid: string) {
  const url = buildUrl(emplid);
  const { data } = await fetchData<API.HttpPlanGetResponse>(url);

  return sortPlanByDate(data);
}

async function getPlanVersion(
  emplid: string,
  planId: string,
  direction: HistoryDirection,
) {
  const url = buildUrl(emplid, planId);
  const { data: rawPlan } = await putData<API.HttpPlanPutResponse>(
    `${url}/version`,
    {
      action: direction,
    },
  );
  // TODO: endpoint `${url}/version` should return both `plan` and `setup`
  const { setup, status } = await getPlan(emplid, planId);
  const plan = preProcessPlanForUI(rawPlan)!;

  return {
    status,
    setup,
    plan,
  };
}

async function getPlan(emplid: string, planId: string) {
  const url = buildUrl(emplid, planId);
  const response = await fetchData<API.HttpPlanGetResponseById>(url);
  // TODO: comment after demo
  maskDemoUsersResponse(response);

  const {
    data: { setup, plan: rawPlan },
    status,
  } = response;

  const plan =
    status === HttpCode.SUCCESS_CODE ? preProcessPlanForUI(rawPlan) : rawPlan;

  return {
    status,
    setup,
    plan,
  };
}

async function savePlan(emplid: string, setup: API.PlanData.Setup) {
  const url = buildUrl(emplid);
  const { data } = await postData<API.HttpPlanPostResponse>(url, setup);

  return data;
}

async function updatePlan(emplid: string, setup: API.PlanData.Setup) {
  const url = buildUrl(emplid, setup.id);
  const { data } = await putData<API.HttpPlanPutResponse>(
    `${url}/configuration`,
    setup,
  );
  return data;
}

async function deletePlan(emplid: string, planId: string) {
  const url = buildUrl(emplid, planId);
  await deleteData(url);

  return true;
}

type PlanPollerParams = {
  planId: string;
  emplid: string;
  onSuccess: (data: {
    status: number;
    plan?: API.PlanData.Plan;
    setup?: API.PlanData.Setup;
  }) => void;
  onError: (error: any) => void;
};

async function planPoller({
  planId,
  emplid,
  onSuccess = (_data) => {},
  onError = (_error) => {},
}: PlanPollerParams) {
  let timerId: NodeJS.Timer;

  console.log(
    ...formatLog({
      scope: 'Plan Poller',
      action: 'Start',
    }),
  );

  // ==============================================================
  async function planPoller({ isInterval = false }) {
    try {
      console.log(
        ...formatLog({
          scope: 'Plan Poller',
          action: isInterval ? 'Interval' : 'Check',
          message: 'Check stored plan',
        }),
      );
      const res = await getPlan(emplid, planId);

      if (res.status === HttpCode.SUCCESS_CODE) {
        clearInterval(timerId);
        onSuccess(res);
        console.log(
          ...formatLog({
            scope: 'PLAN-POLLER',
            action: 'Stop',
            message: `Plan was found`,
          }),
        );
      } else if (res.status === HttpCode.IN_PROGRESS_CODE) {
        console.log(
          ...formatLog({
            scope: 'PLAN-POLLER',
            action: 'In progress',
            message: `Audit Plan still in progress`,
          }),
        );
      } else {
        // any status code neither `SUCCESS_CODE` nor `IN_PROGRESS_CODE`
        // stop the timer. A server error might have happen
        clearInterval(timerId);
        console.log(
          ...formatLog({
            scope: 'PLAN-POLLER',
            action: 'Stop',
            message: `Unhandled ${res.status} received`,
          }),
        );
      }
    } catch (error) {
      clearInterval(timerId);
      onError(error);
      console.log(
        ...formatLog({
          scope: 'PLAN-POLLER',
          action: 'Error',
        }),
        error,
      );
    }
  }
  // ==============================================================
  // Run plan poller
  // ==============================================================
  timerId = setInterval(() => planPoller({ isInterval: true }), 10_000);
  // ==============================================================
  // If an audit was already run get the plan
  // ==============================================================
  await planPoller({ isInterval: false });
}

export {
  planPoller,
  getPlanList,
  getPlan,
  savePlan,
  updatePlan,
  deletePlan,
  getActivePlan,
  updateActivePlan,
  getPlanVersion,
};
