// @ts-check
import { authClient } from 'core/auth/utils/auth-client';
import { clearUserTokens, requestIDPAuthToken } from 'core/services/user-service';
import { decodeJwt } from 'jose';

import { UserManager, WebStorageStateStore } from 'oidc-client-ts'
import { formatLog } from '../../utils/logger-utils';
import { clearState as clearLocalAuthState } from './auth-local-state-service';

class OidcAuthClientService {
  oidcClient = /** @type {UserManager | null} **/ (null);
  settings = /** @type {Required<AuthSettings>} */ ({});


  /**
   * @param {AuthSettings} settings
   * @memberof OidcAuthClientService
   */
  constructor(settings) {
    console.log(...formatLog({ message: 'Creating new Auth-Client...' }));
    this.settings = /** @type {Required<AuthSettings>} */ (settings);

    if(this.oidcClient === null) {
      /* eslint-disable camelcase */
      this.oidcClient = new UserManager({
        metadataUrl: settings.metadataUrl,
        authority: settings.loginUrl || '',
        client_id: settings.clientId || '',
        redirect_uri: settings.redirectUrl + '/oauth2/callback' || '',
        scope: this.settings.scopes.join(' '),
        client_secret: settings.clientSecret,
        automaticSilentRenew: true,
        silent_redirect_uri: settings.redirectUrl + '/token',
        response_type: 'code',
        userStore: new WebStorageStateStore({ store: window.localStorage }),
      });
      /* eslint-enable camelcase */

      this.oidcClient.events.addAccessTokenExpiring(() => {
        requestIDPAuthToken();
      });

      authClient?.setClientInstance(this);
    }
  }

  /** @return {Promise<void>}  */
  async loginWithRedirect() {
    this.oidcClient?.signinRedirect({
      /* eslint-disable camelcase */
      redirect_uri: this.settings.redirectUrl + '/oauth2/callback' || '',
      /* eslint-enable camelcase */
    });
  }


  /**
   * @memberof OidcAuthClientService
   */
  async requestServiceAuthToken() {
    const user = await this.oidcClient?.getUser();
    return user?.access_token;
  }

  getReturnUrl() {
    let returnTo = localStorage.getItem(this.settings.returnToKey);
    localStorage.removeItem(this.settings.returnToKey);

    // If user is logging in from login page, return to redirect url
    if (returnTo?.startsWith(this.settings.loginUrl)) {
      returnTo = '/';
    }

    return returnTo;
  }

  // @ts-ignore
  async processSignIn(onProcessedSignIn) {
    try {
      // @ts-ignore
      await this.oidcClient.signinRedirectCallback(
        window.location.href
      );

      const fetchedAuth = await this.getAuth();

      if (onProcessedSignIn) {
        await onProcessedSignIn(fetchedAuth);
      }

      return fetchedAuth;
    } catch (error) {
      console.log(...formatLog({ message: 'error retrieving authentication: ' }), error);
    }
  }

  async getAuth() {
    try {
      let fetchedAuth;
      if (this.oidcClient) {
        // @ts-ignore-next-line
        fetchedAuth = await this.oidcClient.getUser();
      } else {
        throw new Error('oidcClient not yet initialized');
      }

      return fetchedAuth;
    } catch (error) {
      console.log(...formatLog({ message: 'error retrieving authentication: ' }), error);
    }
  }

  async requestRefreshToken() {
    const user = await this.oidcClient?.getUser();
    return user?.refresh_token;
  }

  /**
   * 1. Check storage and verify valid
   * 2. Use refresh token
   * 3. loginWithRedirect
   * @returns {Promise<AppJwtToken | undefined>}
   */
  async getAuthToken() {
    const user = await this.oidcClient?.getUser();
    return {
      remainingTimeSeconds: user?.expires_in || 0,
      token: user?.id_token || '',
    };
  }

  async logout() {
    // this.oidcClient.signoutRedirect();
    await this.logoutFromCognito();

    localStorage.removeItem(this.settings.tokenKey);
    localStorage.removeItem(this.settings.refreshTokenKey);
    clearLocalAuthState();
    clearUserTokens();

    this.oidcClient?.clearStaleState();
    this.oidcClient?.removeUser();
  }

  async logoutFromCognito() {
    // FIXME:
    // Generally, it should be enough to call `this.oidcClient.signoutRedirect()`,
    //   which would initiate a request to the OIDC endpoint defined by
    //   `end_session_endpoint` (which should be defined in the file @ MetadataUrl)
    // However, AWS Cognito does not include a value for `end_session_endpoint`
    //   in the returned metadata, so to log the User out of their Cognito session,
    //   we'll need to manually navigate to the logout URL.
    // * Source: https://stackoverflow.com/a/56221548

    let logoutUrl = this.settings.authUrl.replace('login', 'logout');
    logoutUrl += `?client_id=${this.settings.clientId}`;
    logoutUrl += `&logout_uri=${encodeURIComponent(this.settings.redirectUrl + '/logout')}`;

    window.location.href = logoutUrl;
}

  async getUser() {
    const token = localStorage.getItem(this.settings.tokenKey);

    if (!token) return '';

    try {
      const payload = decodeJwt(token);

      return String(payload?.email || payload?.sub);
    } catch (error) {
      console.error(error);
    }
  }

  async getTokenClaim() {
    const rawClaim = localStorage.getItem(this.settings.tokenClaimKey);

    if (!rawClaim) return null;

    try {
      const claim = JSON.parse(rawClaim);
      return claim;
    } catch (error) {
      console.error(error);
    }
  }
}

export { OidcAuthClientService };
