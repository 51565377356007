// @ts-check

import * as d3 from 'd3';

import {
  animationDuration,
  barDelay,
  createTooltip,
  transitionHideText,
  transitionText,
} from './d3-helper';

/**
 * @param {ProgressChartType & {svgElement: SVGSVGElement}} param
 */
function createProgressBarChart({
  svgElement,
  width = 300,
  height = 24,
  dataset,
  showInternalBarValue = true,
  showInternalBarValueOnMouseHover = false,
  appendTooltipToBody = true,
  showTooltip = true,
}) {
  const svg = d3.select(svgElement);

  // group container
  const barGroup = svg
    .selectAll('g')
    .data(dataset)
    .join('g')
    .attr('class', (d, i) => `g-progress-bar-${i}`);

  // Progress Bars
  barGroup
    .data(dataset)
    .append('rect')
    .attr('class', 'progress-bar')
    .attr('data-index', (_d, i) => i)
    .attr('x', 0)
    .attr('height', height)
    .transition()
    .duration(animationDuration)
    .delay(barDelay)
    .attr('width', (d) => (d.percent * width) / 100)
    .style('fill', (d) => d.color);

  // Internal values
  if (showInternalBarValue) {
    const barValueGroup = barGroup
      .data(dataset)
      .append('text')
      .text((d, i) => {
        let nextValuePercent = 0;
        if (i === 0) {
          const nextValue = dataset[i + 1];
          nextValuePercent = nextValue.percent;
        }
        let percentDoneValue = d.percent - nextValuePercent;

        return `${percentDoneValue}%`;
      })
      .style('pointer-events', 'none')
      .style('font-size', '0.75rem')
      .style('fill', (d) => d.textColor || '#FFFFFF')
      .attr('x', (d, i) => {
        let nextValueWidth = 0;
        if (i === 0) {
          const nextValue = dataset[i + 1];
          nextValueWidth = (nextValue.percent * width) / 100;
        }
        let percentDoneValue = (d.percent * width) / 100 + nextValueWidth;

        return percentDoneValue / 2;
      })
      .attr('y', height / 2)
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'middle');

    if (showInternalBarValueOnMouseHover) {
      barValueGroup.attr('opacity', 0);

      barGroup
        .on('mouseover', () => {
          barValueGroup.call(transitionText);
        })
        .on('mouseout', () => {
          barValueGroup.call(transitionHideText);
        });
    } else {
      barValueGroup.call(transitionText);
    }
  }

  showTooltip &&
    createTooltip({
      svg,
      dataGroup: barGroup.selectAll('rect'),
      appendToBody: appendTooltipToBody,
      createTooltipText: ({ d }) => d.label,
    });
}

export { createProgressBarChart };
