import { AxiosResponse } from 'axios';
import { HttpCode } from 'core/constants/app-constants';
import { demoUser } from 'dev/mocks/demo-user';

const maskDemoUsersResponse = (response: AxiosResponse<any, any>) => {
  const url = response.config!.url;

  // ===========================================================
  if (url?.includes(`/api/users?search=${demoUser.emplid}`)) {
    response.data = {
      ...response.data,
      profile: {
        ...response.data.profile,
        ...demoUser,
        realData: { ...response.data.profile },
      },
    } satisfies API.UserData.ProfileSearch;
  }
  // ===========================================================
  if (url?.includes(`/api/${demoUser.emplid}/profile`)) {
    response.data = {
      ...response.data,
      ...demoUser,
      realData: { ...response.data },
    } satisfies API.StudentData.Profile;
  }
  // ===========================================================
  if (
    url?.includes(`/api/${demoUser.emplid}/plans`) &&
    response.status === HttpCode.SUCCESS_CODE
  ) {
    let data = response.data as API.HttpPlanGetResponseById;
    response.data = {
      ...data,
      plan: {
        ...data.plan,
        degreePlan: data.plan.degreePlan.map((term) => {
          //====================================================
          const filterClasses = (classes?: API.PlanData.Class[]) =>
            classes?.filter(
              (c) => `${c.subject} ${c.catalogNumber}` !== 'ASU 11',
            );
          //====================================================
          return {
            ...term,
            sessions: term.sessions.map((s) => ({
              ...s,
              classes: filterClasses(s.classes),
              selectedClasses: filterClasses(s.selectedClasses),
            })),
          };
          //====================================================
        }),
      },
    } as API.HttpPlanGetResponseById;
  }
  // ===========================================================
};

export { maskDemoUsersResponse };
