const JWT_IDP_VIEW_AS_ACTIVE_KEY = 'apps.asu.edu.IDP.ViewAs.active';
const JWT_IDP_VIEW_AS_USER_KEY = 'apps.asu.edu.IDP.ViewAs.user';
const JWT_IDP_VIEW_AS_EMPLID_KEY = 'apps.asu.edu.IDP.ViewAs.emplid';
const viewAsStorage = sessionStorage;

function isViewAsActive() {
  const viewAsActive =
    viewAsStorage.getItem(JWT_IDP_VIEW_AS_ACTIVE_KEY) === 'true';
  return viewAsActive;
}

function startViewAsUser(userName: string, emplid: string) {
  viewAsStorage.setItem(JWT_IDP_VIEW_AS_ACTIVE_KEY, 'true');
  viewAsStorage.setItem(JWT_IDP_VIEW_AS_USER_KEY, userName);
  viewAsStorage.setItem(JWT_IDP_VIEW_AS_EMPLID_KEY, emplid);
}

function stopViewAsUser() {
  for (const key of Object.keys(viewAsStorage)) {
    if (key.includes('.ViewAs.')) {
      viewAsStorage.removeItem(key);
    }
  }
}

function getViewAsPayload() {
  const viewAsActive = isViewAsActive();
  const userName = viewAsStorage.getItem(JWT_IDP_VIEW_AS_USER_KEY);
  let payload = null;

  if (viewAsActive && userName) {
    payload = { viewAsSubject: userName };
  }

  return payload;
}

export { startViewAsUser, stopViewAsUser, getViewAsPayload, isViewAsActive };
