import { FC } from 'react';
import styled from 'styled-components';

const Logo = styled.img`
  width: 55px;
  max-height: 34px;
  object-fit: contain;
`;

const NavbarLogo: FC<{sourceData: string}> = (props) => {
  return (
    <Logo className="me-3" alt="logo" src={props.sourceData} />
  );
};

export { NavbarLogo };
