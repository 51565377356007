import { forwardRef } from 'react';
import styled from 'styled-components';
import { Button, Modal } from 'core/components';
import { DialogInstance } from 'core/components/Modal';

const Header = styled.header`
  display: grid;
  place-content: center;
  align-self: start;
  gap: 2rem;

  .ico-container {
    margin: auto;
    background: var(--gray-2) 0% 0% no-repeat padding-box;
    border-radius: 50%;

    display: grid;
    place-content: center;

    width: 140px;
    height: 140px;

    svg {
      height: 56px;
    }
  }

  h2 {
    font-weight: bold;
    font-size: 2rem;
    line-height: 2.375rem;
    letter-spacing: -1.02px;
    color: var(--gray-7);
  }
`;

const Body = styled.p`
  margin: auto;
  font-size: 1.5rem;
  line-height: 1.875rem;
  letter-spacing: 0px;
  color: var(--gray-6);
`;

const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-self: end;
`;

type ConfirmModalProps = {
  type: 'confirm-delete' | 'confirm-reset';
  title: string;
  body: string;
  btnConfirmText: string;
  btnCancelText?: string;
  onConfirm: () => void;
  onClose: () => void;
};

const icon = {
  'confirm-delete': 'fa-solid fa-trash',
  'confirm-reset': 'fa-solid fa-clock-rotate-left',
};

const ConfirmModal = forwardRef<DialogInstance, ConfirmModalProps>(
  (
    {
      type,
      title,
      body,
      btnConfirmText,
      btnCancelText = 'Cancel',
      onConfirm,
      onClose,
    },
    ref,
  ) => {
    return (
      <Modal ref={ref} showCloseXButton={false} width="700px" height="485px">
        <Header>
          <div className="ico-container">
            <i className={icon[type]} />
          </div>
          <h2>{title}</h2>
        </Header>

        <Body>{body}</Body>

        <Footer>
          <Button onClick={onClose}>Cancel</Button>
          <Button buttonType="warning" onClick={onConfirm}>
            {btnConfirmText}
          </Button>
        </Footer>
      </Modal>
    );
  },
);

export { ConfirmModal };
