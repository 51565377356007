import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getGraduationTermOptions } from 'core/services/terms-service';
import { setHttpError } from './sharedSlice';

const initialState: TermsState = {
  graduationTermOptions: [],
};

export const termsSlice = createSlice({
  name: 'terms',
  initialState,
  reducers: {
    setGraduationTermOptions: (
      state,
      action: {
        payload: OptionItem[];
      },
    ) => {
      state.graduationTermOptions = action.payload;
    },
  },
});

export const getGraduationTermsAsync = createAsyncThunk(
  'termsSlice/getGraduationTermsAsync',
  async (payload, { dispatch, getState }) => {
    try {
      const state = getState() as AppState;
      // To avoid repeated calls for data that rarely changes, only retrieve
      // the grad term options if they haven't been retrieved previously
      if (state.terms.graduationTermOptions.length <= 0) {
        const graduationTerms = await getGraduationTermOptions();
        dispatch(setGraduationTermOptions(graduationTerms));
      }
    } catch (error) {
      dispatch(
        setHttpError({
          httpError: (error as HTTPError).message,
          sourceAction: getGraduationTermsAsync.typePrefix,
        }),
      );
    }
  },
);

export const { setGraduationTermOptions } = termsSlice.actions;

export default termsSlice.reducer;
