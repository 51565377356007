const appendClassIds = (
  classType: API.PlanData.ClassType,
  termUId: string,
  sessionUId: string,
  classes?: API.PlanData.Class[],
) => {
  return (classes || []).map((sClass, classIndex) => {
    sClass._uid = `${sessionUId}-class-${classIndex}-of-${classType}`;
    sClass._uiMetaData = {
      classUId: sClass._uid,
      termUId: termUId,
      sessionUId: sessionUId,
      classType,
    };
    return sClass;
  });
};

function preProcessPlanForUI(plan: API.PlanData.Plan | null) {
  if (!plan) return;

  const termSequenceIndex = plan.studentProgress.length + 1;

  plan.studentProgress.forEach((term, termIndex) => {
    // ui metadata
    const termUID = `term-${termIndex}`;
    const sessionUID = `${termUID}-session-${0}`;

    term.classes = appendClassIds(
      'enrolled',
      termUID,
      sessionUID,
      term.classes,
    );
  });

  plan.degreePlan.forEach((term, termIndex) => {
    // ui metadata
    const termUId = `term-${termIndex}`;
    term._uid = termUId;
    term._sequenceIndex = termSequenceIndex + termIndex;
    term.sessions.forEach((session, sessionIndex) => {
      // ui metadata
      const sessionUId = `${term._uid}-session-${sessionIndex}`;
      session._uid = sessionUId;
      session._uiMetaData = {
        termUId,
        sessionUId,
      };
      // classes ids
      session.classes = appendClassIds(
        'enrolled',
        termUId,
        sessionUId,
        session.classes,
      );
      session.requiredClasses = appendClassIds(
        'required',
        termUId,
        sessionUId,
        session.requiredClasses,
      );
      session.selectedClasses = appendClassIds(
        'selected',
        termUId,
        sessionUId,
        session.selectedClasses,
      );
    });
  });

  return plan;
}

export { preProcessPlanForUI };
