// @ts-check
import { createContext, useContext } from 'react';
import { initialAuthState } from '../store';

const stub = () => {
  throw new Error('You forgot to wrap your component in <AuthProvider>');
};

/**
 * @type {AuthContextValue}
 */
const initialContext = {
  state: initialAuthState,
  customSettings: {
    loginUrl: '',
    redirectUrl: '',
    clientId: '',
    scopes: [],
  },
  loginWithRedirect: stub,
  logout: stub,
  loadUserRoles: stub,
  getUserRoles: stub,
  Loader: ({ message }) => <div>{message}</div>,
};

const AuthContext = createContext(initialContext);

/**
 * @template TClaim
 */
const useAuthContext = () => {
  return /** @type {AuthContextValue<TClaim>} */ (useContext(AuthContext));
};

export { AuthContext, useAuthContext };
