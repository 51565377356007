import partnerConfig from '../../partner-config.json';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
const isDevMode = process.env.NODE_ENV === 'development';
const useMockData = process.env.REACT_APP_USE_MOCK_DATA === 'true';
const isStorybook = process.env.STORYBOOK === 'true';
const tlnBaseUrl = partnerConfig?.tln_base_url;
const tlnClientId = partnerConfig?.tln_client_id;
const tlnInstitutionId = partnerConfig?.tln_institution_id;


export {
    apiBaseUrl,
    isStorybook,
    isDevMode,
    useMockData,
    tlnBaseUrl,
    tlnClientId,
    tlnInstitutionId,
};
