import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const BODY_HEIGHT = 150;
const Container = styled.div`
  --accordion-body-height: ${BODY_HEIGHT}px;
`;

type TitleProps = {
  justifyContent?: React.CSSProperties['justifyContent'];
  alignItems?: React.CSSProperties['alignItems'];
};
const StyledTitle = styled.div.attrs<TitleProps>((props) => ({
  justifyContent: props.justifyContent || 'space-between',
  alignItems: props.alignItems || 'center',
}))<TitleProps>`
  display: flex;
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};

  .fa-chevron-down {
    display: block;
    transition: transform 0.3s;
  }

  [data-collapsed='true'] & {
    .fa-chevron-down {
      transform: matrix(-1, 0, 0, -1, 0, 0);
    }
  }

  .heading {
    color: var(--gray-7);
    font-size: 1rem;
    font-weight: bold;
    line-height: 1;
    margin: 0;
    [data-collapsed='true'] & {
      margin-block-end: 0.5rem;
    }
  }

  button {
    cursor: pointer;
    background: transparent;
    border: none;
  }
`;

const StyledBody = styled.div`
  display: grid;
  grid-template-rows: 0fr;
  opacity: 0;
  transition:
    grid-template-rows ease 0.6s,
    opacity ease 0.6s;

  & > * {
    overflow: hidden;
    display: grid !important;
  }

  &[data-collapsed='false'] > * {
    visibility: hidden;
  }

  [data-collapsed='true'] & {
    grid-template-rows: 1fr;
    opacity: 1;
  }

  [data-collapsed='false'] & {
    overflow-x: hidden;
  }
`;

type AccordionProps = {
  children: React.ReactElement;
  titleTag?: keyof JSX.IntrinsicElements;
  titleJustify?: 'space-between' | 'start';
  titleAlign?: 'center' | 'start' | 'end';
  className?: string;
  bodyClassName?: string;
  bodyHeight?: number;
  defaultCollapsed?: boolean;
  onCollapse?: (collapsed: boolean) => void;
} & (
  | {
      title: string;
      renderTitle?: never;
    }
  | {
      title?: never;
      renderTitle: React.ReactNode;
    }
);

const Accordion: FC<AccordionProps> = ({
  className,
  titleTag = 'h3',
  titleJustify,
  titleAlign,
  renderTitle,
  bodyClassName,
  title,
  bodyHeight = BODY_HEIGHT,
  defaultCollapsed = false,
  children,
  onCollapse = () => undefined,
}) => {
  const [collapsed, setCollapsed] = useState(defaultCollapsed);
  const containerRef = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const Header = titleTag;

  useEffect(() => {
    if (!containerRef.current || !bodyRef.current) return;
    const child = bodyRef.current.firstChild as HTMLElement;
    const computedBodyHeight = `${
      child.offsetHeight < bodyHeight ? child.offsetHeight : bodyHeight
    }px`;
    containerRef.current.style.setProperty(
      '--accordion-body-height',
      computedBodyHeight,
    );
  }, [bodyHeight]);

  return (
    <Container
      ref={containerRef}
      className={className}
      data-collapsed={collapsed}
    >
      <StyledTitle justifyContent={titleJustify} alignItems={titleAlign}>
        {renderTitle ?? <Header className="heading">{title}</Header>}
        <button
          onClick={() => {
            const newValue = !collapsed;
            setCollapsed(newValue);
            onCollapse(newValue);
          }}
        >
          <i className="fa-solid fa-chevron-down" />
        </button>
      </StyledTitle>
      <StyledBody
        ref={bodyRef}
        className={bodyClassName}
        data-collapsed={collapsed}
        onKeyDown={(e) => {
          if (!collapsed && (e.key === 'Enter' || e.key === ' ')) {
            e.preventDefault();
            return false;
          }
        }}
      >
        {children}
      </StyledBody>
    </Container>
  );
};

export { Accordion };
