import { FC, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { createProgressBarChart } from '../helpers/d3/create-progress-bar-chart';
import '../helpers/d3/create-progress-bar-chart.css';
import { removeChartElements } from '../helpers/d3/d3-helper';

const defaultWorkDoneColor = 'var(--gray-6)';
const defaultWorkLeftColor = 'var(--gray-3)';

const Container = styled.section`
  .chart-totals {
    p {
      font-size: 0.875rem;
      line-height: 1.25rem;
      letter-spacing: 0px;
      margin: 0;
      margin-bottom: 0.5rem;
    }
  }
`;

type ProgressBarChartProps = {
  workDonePercent: number;
  workLeftPercent: number;
  areaTitle1?: string;
  areaTitle2?: string;
  width?: number;
  height?: number;
  areaColor1?: string;
  areaColor2?: string;
  showTooltip?: boolean;
};

const ProgressBarChart: FC<ProgressBarChartProps> = ({
  workDonePercent = 0,
  workLeftPercent = 0,
  areaColor1 = defaultWorkLeftColor,
  areaColor2 = defaultWorkDoneColor,
  areaTitle1 = `Total remaining hours`,
  areaTitle2 = `Total accomplished hours`,
  width = 280,
  height = 24,
  showTooltip = true,
}) => {
  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    const svgElement = svgRef.current;

    const areaWorkInLeft: ProgressBarChartData = {
      label: areaTitle1,
      percent: 100,
      color: areaColor1,
      textColor: '#191919',
    };
    const areaWorkDone = {
      label: areaTitle2,
      percent: workDonePercent,
      color: areaColor2,
      textColor: '#FFFFFF',
    };
    const dataset: ProgressChartDataset = [areaWorkInLeft, areaWorkDone];

    if (svgElement && dataset) {
      createProgressBarChart({
        svgElement,
        dataset,
        progressDone: workDonePercent,
        width,
        height,
        showTooltip,
        showInternalBarValueOnMouseHover: true,
      });
    }

    return () => {
      removeChartElements({
        svgElement,
      });
    };
  }, [
    areaColor1,
    areaColor2,
    areaTitle1,
    areaTitle2,
    height,
    showTooltip,
    width,
    workDonePercent,
  ]);

  return (
    <Container>
      <section className="chart-totals">
        <p>{workDonePercent}% of remaining hours planned</p>
      </section>

      <section className="chart-area">
        <svg
          ref={svgRef}
          data-type="progress-bar-chart"
          width={width}
          height={height}
          style={{
            border: '1px solid gray',
          }}
        />
      </section>
    </Container>
  );
};

export { ProgressBarChart };
