import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import 'App.css';
import { ProtectedRoute } from 'core/auth';
import { roleList, RoleName } from 'core/constants/app-constants';
import { appRouterUrl } from 'core/constants/router-url';
import { useScrollbarSettings } from 'core/hooks/use-scrollbar-settings';
import { useWebpackError } from 'core/hooks/use-webpack-error';
import { useAppDispatch } from 'core/store';
import { setSelectedRoute } from 'core/store/slices';
//#region "pages"
import { PageLayout } from 'pages/PageLayout';
import TlnAcknowledge from 'pages/Student/Tln/TlnAcknowledge';
import TlnRequest from 'pages/Student/Tln/TlnRequest';

const Dashboard = React.lazy(() => import('./pages/Student/Dashboard'));
const PlanList = React.lazy(() => import('./pages/Student/PlanList'));
const PlanDetail = React.lazy(() => import('./pages/Student/PlanDetail'));
const ComparisonList = React.lazy(
  () => import('./pages/Student/ComparisonList'),
);
const StudentProfile = React.lazy(() => import('./pages/Student/Profile'));
const AdminDashboard = React.lazy(() => import('./pages/Admin/Dashboard'));
const AdminProfileView = React.lazy(() => import('./pages/Admin/ProfileView'));
const AdminReports = React.lazy(() => import('./pages/Admin/Reports'));
const AdminSettings = React.lazy(() => import('./pages/Admin/Settings'));

const NotAuthorized = React.lazy(() => import('./pages/NotAuthorized'));
const NotFound = React.lazy(() => import('./pages/NotFound'));
const LogOut = React.lazy(() => import('./pages/LogOut'));
const LogInLanding = React.lazy(() => import('./pages/LogInLanding'));
//#endregion

const allRoles = roleList;
const adminRoles = [RoleName.ADMIN, RoleName.PLATFORM];
const advisorRoles = [RoleName.ADVISOR, RoleName.COACH];

function App() {
  const dispatch = useAppDispatch();
  const location = useLocation();

  useWebpackError();
  useScrollbarSettings();

  useEffect(() => {
    dispatch(setSelectedRoute(location.pathname));
  }, [dispatch, location]);

  return (
    <Routes>
      <Route path="/" element={<PageLayout />}>
        {/* =============================================
                         "Student pages"
            ============================================= */}
        <Route
          path={appRouterUrl.STUDENT_PROFILE}
          element={
            <ProtectedRoute roles={allRoles} lazy>
              <StudentProfile />
            </ProtectedRoute>
          }
        />

        <Route
          path={appRouterUrl.HOME}
          element={
            <ProtectedRoute roles={allRoles} lazy>
              <Navigate to={appRouterUrl.STUDENT_DASHBOARD} />
            </ProtectedRoute>
          }
        />

        <Route
          path={appRouterUrl.STUDENT_DASHBOARD}
          element={
            <ProtectedRoute roles={allRoles} lazy>
              <Dashboard />
            </ProtectedRoute>
          }
        />

        <Route
          path={appRouterUrl.COMPARISON_LIST}
          element={
            <ProtectedRoute roles={allRoles} lazy>
              <ComparisonList />
            </ProtectedRoute>
          }
        />

        <Route
          path={appRouterUrl.PLANS_LIST}
          element={
            <ProtectedRoute roles={allRoles} lazy>
              <PlanList />
            </ProtectedRoute>
          }
        />

        <Route
          path={appRouterUrl.PLAN_DETAIL_BY_PLAN_ID}
          element={
            <ProtectedRoute roles={allRoles} lazy>
              <PlanDetail />
            </ProtectedRoute>
          }
        />

        <Route
          path={appRouterUrl.TLN_REQUEST}
          element={
            <ProtectedRoute roles={allRoles} lazy>
              <TlnRequest />
            </ProtectedRoute>
          }
        />
        <Route
          path={appRouterUrl.TLN_RESPONSE}
          element={
            <ProtectedRoute roles={allRoles} lazy>
              <TlnAcknowledge />
            </ProtectedRoute>
          }
        />

        {/* =============================================
                         "Admin pages"
            ============================================= */}
        {[
          appRouterUrl.ADMIN_DASHBOARD,
          appRouterUrl.ADVISOR_DASHBOARD,
          appRouterUrl.COACH_DASHBOARD,
        ].map((path) => (
          <Route
            key={path}
            path={path}
            element={
              <ProtectedRoute roles={[...adminRoles, ...advisorRoles]} lazy>
                <AdminDashboard />
              </ProtectedRoute>
            }
          />
        ))}

        <Route
          path={appRouterUrl.ADMIN_PROFILE_VIEW}
          element={
            <ProtectedRoute roles={adminRoles} lazy>
              <AdminProfileView />
            </ProtectedRoute>
          }
        />

        <Route
          path={appRouterUrl.ADMIN_SETTINGS}
          element={
            <ProtectedRoute roles={adminRoles} lazy>
              <AdminSettings />
            </ProtectedRoute>
          }
        />

        <Route
          path={appRouterUrl.ADMIN_REPORTS}
          element={
            <ProtectedRoute roles={adminRoles} lazy>
              <AdminReports />
            </ProtectedRoute>
          }
        />
        {/* =============================================
                         "Other pages"
            ============================================= */}
        <Route path={appRouterUrl.LOG_OUT} element={<LogOut />} />
        <Route path={appRouterUrl.LOG_IN} element={<LogInLanding />} />
        <Route path={appRouterUrl.NOT_AUTHORIZED} element={<NotAuthorized />} />
        {['*', appRouterUrl.NOT_FOUND].map((path) => (
          <Route key={path} path={path} element={<NotFound />} />
        ))}
      </Route>
    </Routes>
  );
}

export default App;
