import { FC, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { createStackedBarChart } from '../helpers/d3/create-stacked-bar-chart';
import '../helpers/d3/create-stacked-bar-chart.css';
import { removeChartElements } from '../helpers/d3/d3-helper';

const CURRENT_MAJOR_COLOR = 'var(--current-major-bg-color)';
const TARGET_MAJOR_COLOR = 'var(--target-major-bg-color)';
const CURRENT_MAJOR_TEXT_COLOR = 'var(--on-current-major-bg-color)';
const TARGET_MAJOR_TEXT_COLOR = 'var(--on-target-major-bg-color)';

const defaultAreaColor1 = CURRENT_MAJOR_COLOR;
const defaultAreaColor2 = TARGET_MAJOR_COLOR;

const Container = styled.section`
  display: grid;

  ul {
    list-style: none;
    padding: 0;
  }

  &[data-view-template-type='cols'] {
    grid-template-areas: 'chart-legend' 'chart-area' 'chart-totals';
  }

  &[data-view-template-type='rows'] {
  }

  .chart-legend {
    grid-area: chart-legend;

    .legend-list {
      .legend-item {
        font-size: 1rem;
        letter-spacing: 0px;
        color: #191919;
        text-shadow: 0px 3px 6px #00000029;
        display: grid;
        grid-auto-flow: column;
        align-items: baseline;
        justify-content: flex-start;
        gap: 1rem;

        &:first-child {
          margin-bottom: 1rem;
          --bullet-color: ${defaultAreaColor1};
        }

        &:last-child {
          --bullet-color: ${defaultAreaColor2};
        }

        &::before {
          content: '';
          width: 1rem;
          height: 1rem;
          background: var(--bullet-color) 0% 0% no-repeat padding-box;
          border-radius: 12px;
          position: relative;
          top: 2px;
        }
      }
    }
  }

  .chart-area {
    grid-area: chart-area;
    margin-bottom: 3rem;
  }

  .chart-totals {
    grid-area: chart-totals;
    .item-title {
      margin-right: 0.5rem;
      margin-bottom: 0;
    }
  }
`;

type StackedBarChartProps = StackedBarChartType & {
  dataViewTemplateType: 'rows' | 'cols';
  remainingUpperDivisionHours: number;
  unusedHours: number;
  cumulativeGPA: number;
};
const StackedBarChart: FC<StackedBarChartProps> = ({
  dataViewTemplateType = 'cols',
  remainingUpperDivisionHours,
  unusedHours,
  cumulativeGPA,
  dataset,
  areaColor1 = defaultAreaColor1,
  areaColor2 = defaultAreaColor2,
  areaTitle1 = '',
  areaTitle2 = '',
  width = 440,
  height = 400,
  barWidth = 84,
  showLegend = false,
  showAxisY = false,
  showAxisX = false,
  showTooltip = true,
  appendTooltipToBody,
}) => {
  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    const svgElement = svgRef.current;

    if (svgElement && dataset?.length > 0) {
      createStackedBarChart({
        svgElement,
        dataset,
        width,
        height,
        barWidth,
        marginChart: {
          top: 20,
          bottom: 20,
        },
        areaColor1,
        areaColor2,
        areaTextColor1: CURRENT_MAJOR_TEXT_COLOR,
        areaTextColor2: TARGET_MAJOR_TEXT_COLOR,
        areaTitle1,
        areaTitle2,
        showLegend,
        showAxisY,
        showAxisX,
        showTooltip,
        appendTooltipToBody,
        showInternalBarValueOnMouseHover: true,
      });
    }

    return () => {
      removeChartElements({
        svgElement,
      });
    };
  }, [
    dataset,
    height,
    width,
    areaColor1,
    areaColor2,
    areaTitle1,
    areaTitle2,
    showAxisX,
    showAxisY,
    showLegend,
    showTooltip,
    appendTooltipToBody,
    barWidth,
  ]);

  return (
    <Container data-view-template-type={dataViewTemplateType}>
      {/* <section className="chart-legend">
        <ProgramList program1={areaTitle1} program2={areaTitle2} />
      </section> */}

      <section className="chart-area">
        <svg ref={svgRef} data-type="stacked-bar-chart" />
      </section>

      <section className="chart-totals">
        <ul>
          <li>
            <strong className="item-title">
              Remaining Upper Division Hours:
            </strong>
            <span>{remainingUpperDivisionHours}</span>
          </li>
          <li>
            <strong className="item-title">Unused Hours:</strong>
            <span>{unusedHours}</span>
          </li>
          <li>
            <strong className="item-title">Cumulative GPA:</strong>
            {/* <span>{(cumulativeGPA + '.').padEnd(4, '0')}</span> */}
            <span>0.00</span>
          </li>
        </ul>
      </section>
    </Container>
  );
};

export { StackedBarChart, defaultAreaColor1, defaultAreaColor2 };
