import React, { FC } from 'react';
import styled from 'styled-components';
import { SpinnerLoader } from '..';

const Container = styled.div`
  background-color: var(--white);
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  position: relative;
`;

type BoxProps = {
  className?: string;
  loaderMessage?: string | null;
  children: React.ReactNode | React.ReactNode[];
};

const Box: FC<BoxProps> = ({ className, loaderMessage, children }) => {
  return (
    <>
      <Container className={className}>
        {loaderMessage && (
          <SpinnerLoader
            spinnerMessage={loaderMessage}
            spinnerType="circle"
            width="20px"
            height="20px"
            attachContainer="element"
            fullOpacity
          />
        )}
        {children}
      </Container>
    </>
  );
};

export { Box };
