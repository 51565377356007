import { createSlice } from '@reduxjs/toolkit';

const initialState: SharedState = {
  selectedRoute: undefined,
  pageLoading: false,
  resourceNotFound: false,
  showNavbar: true,
  feedback: {
    errorMessage: '',
    successMessage: '',
    infoMessage: '',
    loadingMessage: '',
  },
  httpFeedback: {
    hasHttpError: false,
    networkErrorMessage: '',
    notAuthorizedError: false,
    errorSource: '',
  },
};

export const sharedSlice = createSlice({
  name: 'shared',
  initialState,
  reducers: {
    setSelectedRoute: (state, action: ActionOf<string>) => {
      state.selectedRoute = action.payload;
    },
    setShowNavbar: (state, action: ActionOf<boolean>) => {
      state.showNavbar = action.payload;
    },
    setPageLoading: (state, action: ActionOf<boolean>) => {
      state.pageLoading = action.payload;
    },
    setResourceNotFound: (state, action: ActionOf<boolean>) => {
      state.resourceNotFound = action.payload;
    },
    setInfoMessage: (state, action: ActionOf<string>) => {
      state.feedback.infoMessage = action.payload;
    },
    setErrorMessage: (state, action: ActionOf<string>) => {
      state.feedback.errorMessage = action.payload;
    },
    setLoadingMessage: (state, action: ActionOf<string | string[]>) => {
      state.feedback.loadingMessage = action.payload;
    },
    setHttpError: (
      state,
      action: ActionOf<{
        httpError: string;
        sourceAction: string;
        notAuthorized?: boolean;
      }>,
    ) => {
      const {
        payload: { httpError, sourceAction, notAuthorized },
      } = action;
      const networkErrorMessage = `[Action: ${sourceAction}]: ${httpError}`;

      console.error(networkErrorMessage);

      state.httpFeedback.hasHttpError = true;
      state.httpFeedback.errorSource = sourceAction;
      state.httpFeedback.networkErrorMessage = networkErrorMessage;
      state.httpFeedback.notAuthorizedError = notAuthorized;
    },
    resetHttpError: (state) => {
      state.httpFeedback = {
        ...initialState.httpFeedback,
      };
    },
    resetAlertMessages: (state) => {
      state.feedback = {
        ...initialState.feedback,
      };
    },
  },
});

export const {
  setPageLoading,
  setLoadingMessage,
  setInfoMessage,
  setErrorMessage,
  resetAlertMessages,
  setResourceNotFound,
  setHttpError,
  resetHttpError,
  setSelectedRoute,
  setShowNavbar,
} = sharedSlice.actions;

export default sharedSlice.reducer;
