function cloneObject<T = any>(obj: object) {
  if ('structuredClone' in window) {
    return structuredClone(obj) as T;
  }
  return JSON.parse(JSON.stringify(obj)) as T;
}

function cloneDraftObject<T extends object = {}>(obj: object) {
  return JSON.parse(JSON.stringify(obj)) as T;
}

export { cloneObject, cloneDraftObject };
