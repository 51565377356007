import React, { FC } from 'react';
import styled from 'styled-components';
import { ErrorMessage } from '../ErrorMessage';

const Container = styled.div`
  form.uds-form &.form-group {
    margin: 0 0 1.5rem 0;
  }

  label {
    margin-bottom: 0.55rem;
    font-size: 1rem;
    line-height: 1.25rem;
    letter-spacing: -0.08px;
    font-weight: bold;
  }

  &[data-bold-label='false'] {
    label {
      font-weight: normal;
    }
  }

  &:where([data-input-type='radio'], [data-input-type='checkbox']) {
    align-items: flex-start;

    &[data-input-type='radio'] {
      label::before,
      label::after {
        border-radius: 50%;
      }
    }

    .input-container {
      width: 100%;
      display: flex;
      flex-flow: row;
      align-items: center;
      gap: 0.5rem;

      input:focus + label:after,
      input:focus + label:before {
        border: 2px solid #191919;
      }

      label {
        width: 100%;
        order: 1;

        position: relative;
        display: inline-block;
        cursor: pointer;
        margin-left: 1rem;

        &::before {
          content: '';
          position: absolute;
          display: inline-block;
          left: -2.25rem;
          border: 1px solid #747474;
          width: 1.5rem;
          height: 1.5rem;
          background: #fff;
        }

        &::after {
          content: '';
          position: absolute;
          display: inline-block;
          left: -1.875rem;
          top: 0.375rem;
          width: 0.75rem;
          height: 0.75rem;
        }

        &[data-checked='true']::after {
          background: #000;
        }
      }

      input.form-check-input {
        width: auto;
        opacity: 0;
        position: relative;
        margin-top: 0;
        margin-left: 0;
      }
    }
  }
`;

type InputFieldProps = GenericFieldProps & {
  type: React.HTMLInputTypeAttribute;
  name?: string;
  className?: string;
  checked?: boolean;
  boldLabel?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
};

const InputField: FC<InputFieldProps> = ({
  id: inputId,
  name,
  label,
  value,
  placeholder,
  checked,
  required,
  error,
  boldLabel = true,
  type = 'text',
  className = '',
  style = {},
  onChange,
}) => {
  const id = inputId || label.replaceAll(' ', '-');
  let inputClasses = '';
  let labelClasses = '';

  const isOptionInput = ['radio', 'checkbox'].includes(type);

  if (isOptionInput) {
    inputClasses = 'form-check-input';
    labelClasses = 'form-check-label';
  }

  const LabelElement = () => (
    <label data-checked={checked} htmlFor={id} className={`${labelClasses}`}>
      {label}
    </label>
  );

  return (
    <Container
      data-input-type={type}
      data-bold-label={boldLabel}
      className={`form-group ${className}`}
      style={style}
    >
      <div className="input-container">
        {!isOptionInput && <LabelElement />}
        <input
          id={id}
          name={name}
          className={`form-control ${inputClasses}`}
          type={type}
          placeholder={placeholder}
          value={value}
          required={required}
          checked={checked}
          onChange={onChange}
        />
        {isOptionInput && <LabelElement />}
      </div>

      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  );
};

export { InputField };
