function pickEmplid(appState: unknown, useSearchProfile = false): string {
  const state = appState as AppState;
  const { viewAs, emplid, search } = state.userProfile;
  let currentEmplid = emplid;

  if (useSearchProfile) {
    currentEmplid = search.selectedUser?.profile.emplid || '';
  } else if (viewAs.active) {
    currentEmplid = viewAs.emplid;
  }

  return currentEmplid || '';
}

export { pickEmplid };
