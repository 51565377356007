// @ts-check

import { useEffect, useState } from 'react';

/**
 * @example const isMobile = useMedia("(max-width: 600px)");
 * @param query A string specifying the media query to parse into a MediaQueryList.
 */
function useMedia(query: string): boolean {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    // when the page first loaded
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    // when the page changed view port
    const listener = () => {
      setMatches(media.matches);
    };

    media.addEventListener('change', listener);

    return () => media.removeEventListener('change', listener);
  }, [matches, query]);

  return matches;
}

export { useMedia };
