import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { authClient } from 'core/auth';
import { apiBaseUrl } from 'core/constants/app-settings';
import { getIDPAuthToken } from 'core/services/user-service';

const API = axios.create({
  baseURL: apiBaseUrl,
});

API.interceptors.request.use(
  async (config) => {
    const clientInstance = authClient?.getClientInstance();
    const serviceAuthToken = await clientInstance?.getAuthToken();
    const idpAuthToken = await getIDPAuthToken();
    let jwt;

    if (config.headers['Use-ServiceAuth-Token']) {
      jwt = serviceAuthToken;
    } else {
      jwt = idpAuthToken;
    }

    config.headers['Authorization'] = `Bearer ${jwt?.token}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

const fetchData = async <TDataResponse>(
  url: string,
  searchParams = {} as URLSearchParams,
  config: AxiosRequestConfig = {},
) => {
  return API.get<TDataResponse>(url, {
    params: searchParams,
    ...config,
  });
};

const postData = async <TDataResponse, TPayloadRequest = any>(
  url: string,
  data: TPayloadRequest,
  config: AxiosRequestConfig = {},
) => {
  return API.post<TDataResponse, AxiosResponse<TDataResponse>, TPayloadRequest>(
    url,
    data,
    {
      ...config,
    },
  );
};

const putData = async <TDataResponse>(
  url: string,
  data: any,
  config: AxiosRequestConfig = {},
) => {
  return API.put<TDataResponse>(url, data, {
    ...config,
  });
};

const deleteData = async <TDataResponse>(
  url: string,
  config: AxiosRequestConfig = {},
) => {
  return API.delete<TDataResponse>(url, {
    ...config,
  });
};

// Once the web API will be ready, this will be replaced either by `fetch` or `axios` API
function fakeData<T>(
  mockData = {} as T,
  delay = 200,
): Promise<AxiosResponse<T, any>> {
  return new Promise((resolve) =>
    setTimeout(() => {
      resolve({
        data: mockData,
        status: 200,
        statusText: 'OK',
      } as AxiosResponse<T, any>);
    }, delay),
  );
}

export { fetchData, postData, putData, deleteData, fakeData };
