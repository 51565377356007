// @ts-check
import partnerConfig from '../../../../partner-config.json';

const name = process.env.APP_NAME || 'apps.asu.edu';

//  ================================================================================
// Service auth URIs
//  ================================================================================

// ASU Auth config:
// const SERVICE_AUTH_BASE_URL = `https://weblogin.asu.edu/serviceauth/oauth2`;
// const defaultAuthUrl = `${SERVICE_AUTH_BASE_URL}/native/allow`;
// const defaultTokenUrl = `${SERVICE_AUTH_BASE_URL}/native/token`;
// const defaultRefreshTokenUrl = `${SERVICE_AUTH_BASE_URL}/token`;
// const defaultClientSecret = 'serviceauth-public-agent';
// const defaultDplBaseUrl = 'https://api.myasuplat-dpl.asu.edu/api';

// AWS Cognito Auth config:
const SERVICE_AUTH_BASE_URL = partnerConfig?.cognito_authorization_url;
const defaultAuthUrl = `${SERVICE_AUTH_BASE_URL}/login`;
const defaultTokenUrl = `${SERVICE_AUTH_BASE_URL}/token/`;
const defaultRefreshTokenUrl = `${SERVICE_AUTH_BASE_URL}/token`;
const defaultClientSecret = 'serviceauth-public-agent';
const defaultDplBaseUrl = 'https://api.myasuplat-dpl.asu.edu/api';
//  ================================================================================

const env = process.env;

/** @type {AuthSettings} */
const authSettings = {
  //#region "webapp auth settings"
  loginUrl: undefined,
  redirectUrl: undefined,
  notAuthorizedUrl: undefined,
  scopes: undefined,
  clientId: undefined,
  //#endregion

  //#region "service auth URIs"
  authUrl: env.REACT_APP_AUTH_URL || defaultAuthUrl,
  tokenUrl: env.REACT_APP_TOKEN_URL || defaultTokenUrl,
  refreshTokenUrl: env.REACT_APP_REFRESH_TOKEN_URL || defaultRefreshTokenUrl,
  clientSecret: env.REACT_APP_CLIENT_SECRET || defaultClientSecret,
  dplBaseUrl: env.REACT_APP_DPL_BASE_URL || defaultDplBaseUrl,
  //#endregion

  //#region "JWT Token"
  tokenClaimKey: `${name}.jwt.claim`,
  tokenKey: `${name}.jwt.token`,
  tokenKeyCreateTime: `${name}.jwt.token.create-time`,
  tokenKeyUpdateTime: `${name}.jwt.token.update-time`,
  refreshTokenKey: `${name}.jwt.refresh.token`,
  codeVerifierKey: `${name}.serviceauth.codeVerifier`,
  stateKey: `${name}.serviceauth.state`,
  returnToKey: `${name}.app.returnTo`,
  appStateKey: `${name}.app.state`,
  //#endregion
};

export { authSettings };
