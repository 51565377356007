type ScrollToElementSetting = Partial<{
  focused: boolean;
  childFocused: boolean;
  behavior?: ScrollBehavior;
  block?: ScrollLogicalPosition;
  inline?: ScrollLogicalPosition;
}>;

function scrollTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}

function scrollToElement(
  elementSelector: string,
  settings?: ScrollToElementSetting,
) {
  const element = document.querySelector(elementSelector) as HTMLElement;
  element?.scrollIntoView({
    behavior: settings?.behavior || 'smooth',
    block: settings?.block || 'nearest',
    inline: settings?.inline,
  });

  settings?.focused && element?.focus();

  if (settings?.childFocused && element) {
    const input = element.querySelector('input, textarea') as HTMLInputElement;
    input && setTimeout(() => input.focus(), 800);
  }
}

/**
 * Create an element on the fly to check if the OS scrollbar are active
 */
function getScrollbarWidth() {
  // Create an offscreen element
  const element = document.createElement('div');
  element.style.width = '100px';
  element.style.height = '100px';
  element.style.overflow = 'scroll';
  element.style.visibility = 'hidden';
  document.body.appendChild(element);

  // Calculate the scrollbar width
  const scrollbarWidth = element.offsetWidth - element.clientWidth;

  // Remove the offscreen element
  document.body.removeChild(element);

  return scrollbarWidth;
}

export { scrollToElement, scrollTop, getScrollbarWidth };
