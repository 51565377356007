import { forwardRef } from 'react';
import styled from 'styled-components';
import { Button, Modal } from 'core/components';
import { DialogInstance } from 'core/components/Modal';

const Header = styled.header`
  display: grid;
  place-content: center;
  align-self: start;
  gap: 2rem;

  .ico-container {
    margin: auto;
    background: var(--gray-2) 0% 0% no-repeat padding-box;
    border-radius: 50%;

    display: grid;
    place-content: center;

    width: 140px;
    height: 140px;

    svg {
      height: 56px;
    }
  }

  h2 {
    font-weight: bold;
    font-size: 2rem;
    line-height: 2.375rem;
    letter-spacing: -1.02px;
    text-align: center;
    color: var(--gray-7);
  }
`;

const Body = styled.p`
  margin-inline: auto;
  margin-block: 1rem !important;
  font-size: 1.5rem;
  line-height: 1.875rem;
  letter-spacing: 0px;
  color: var(--gray-6);
`;

const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-self: end;
  justify-content: center;

  &[data-type='OK'] {
    button {
      min-width: 150px;
    }
  }
`;

const iconType = {
  delete: 'fa-solid fa-trash',
  reset: 'fa-solid fa-clock-rotate-left',
  info: 'fa-solid fa-question',
  warning: 'fa-solid fa-exclamation',
};

type ConfirmModalProps = {
  showModal: boolean;
  icon: keyof typeof iconType;
  type?: 'OK' | 'OK_CANCEL';
  title: string;
  body?: string;
  btnConfirmText?: string;
  btnCancelText?: string;
  width?: React.CSSProperties['width'];
  height?: React.CSSProperties['height'];
  onConfirm: () => void;
  onClose: () => void;
};

const AlertModal = forwardRef<DialogInstance, ConfirmModalProps>(
  (
    {
      showModal,
      type = 'OK',
      icon,
      title,
      body,
      btnConfirmText = 'Ok',
      btnCancelText = 'Cancel',
      width,
      height,
      onConfirm,
      onClose,
    },
    ref,
  ) => {
    return (
      <Modal
        ref={ref}
        showModal={showModal}
        showCloseXButton={false}
        width={width}
        height={height}
        onClose={onClose}
      >
        <Header>
          <div className="ico-container">
            <i className={iconType[icon]} />
          </div>
          <h2>{title}</h2>
        </Header>

        {body && <Body>{body}</Body>}

        <Footer data-type={type}>
          {type === 'OK_CANCEL' && (
            <Button onClick={onClose}>{btnCancelText}</Button>
          )}
          <Button buttonType="primary" onClick={onConfirm}>
            {btnConfirmText}
          </Button>
        </Footer>
      </Modal>
    );
  },
);

export { AlertModal };
