import {
  Children,
  cloneElement,
  ElementRef,
  FC,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';

type ListProps = {
  minWidth: string;
};

const List = styled.ul.attrs<ListProps>(({ minWidth }) => ({
  minWidth,
}))<ListProps>`
  position: fixed;
  display: none;
  z-index: 20000;
  list-style: none;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  margin: 0;
  padding: 0;
  padding-block: 0.5rem;

  font-size: 1rem;
  width: max-content;
  min-width: ${(props) => props.minWidth};

  &[data-is-open='true'] {
    display: block;
  }

  li {
    padding-inline: 1rem;
    cursor: pointer;
    letter-spacing: 0px;
    color: #191919;

    a {
      width: 100%;
      color: inherit;
      &:hover {
        color: #191919;
        text-decoration: underline;
      }
    }
  }
`;

type ReactMouseEvent = React.MouseEvent<HTMLElement, MouseEvent>;
type ReactKeyboardEvent = React.KeyboardEvent<HTMLElement>;
type OpenMenuEvent = { e: ReactMouseEvent; delay: boolean };

//#region "MenuItem"
type MenuItemProps = {
  children: ReactElement;
  /**
   * When `false` (default) the MenuList allow navigation between `<li />` items
   * Whe `true` the MenuList expects that the `tabindex` is set externally.
   * @example
   * <MenuItem><a>My link</a></MenuItem>
   * @example
   * <MenuItem><button>My link</button></MenuItem>
   * @example
   * <MenuItem><div tabIndex={0}>My link</div></MenuItem>
   */
  customTabIndex?: boolean;
} & React.FormHTMLAttributes<HTMLLIElement>;

const MenuItem = ({
  children,
  customTabIndex = false,
  ...props
}: MenuItemProps) => {
  const selectMenuItem = (e: ReactKeyboardEvent) => {
    if (!['ArrowUp', 'ArrowDown'].includes(e.key)) {
      return;
    }

    e.stopPropagation();
    e.preventDefault();

    const target = e.currentTarget as HTMLElement;
    let sibling = null;

    if (e.key === 'ArrowUp') {
      sibling = target.previousSibling as HTMLElement;
    } else if (e.key === 'ArrowDown') {
      sibling = target.nextSibling as HTMLElement;
    }

    if (sibling) {
      const element = sibling.hasAttribute('tabindex')
        ? sibling
        : sibling.querySelector<HTMLElement>('a,[tabindex="0"]');

      if (element?.focus) {
        element.focus();
      }
    }
  };

  return (
    <li
      {...props}
      role="menuitem"
      tabIndex={customTabIndex ? undefined : 0}
      onKeyDown={selectMenuItem}
    >
      {children}
    </li>
  );
};
//#endregion

//#region "MenuList"

type MenuChild = ReactElement;
type MenuTriggerProps = React.FormHTMLAttributes<HTMLElement> & {
  ref: React.RefObject<any>;
};
type MenuProps = {
  className?: string;
  children: MenuChild | MenuChild[];
  disabled?: boolean;
  minWidth?: string;
  /**
   * set which direction the MenuList shows up
   */
  direction?: 'right' | 'left';
  /**
   * Any valid JSX element to which attach the events to show the MenuList
   *  @example
   * (props) =>(<Button {...props}>Click to open the menu</Button>)
   */
  triggerComponent: (props: MenuTriggerProps) => ReactElement;
  onClick?: React.MouseEventHandler<HTMLElement>;
};

const DELAY = 500;
let menuEnterTimer: NodeJS.Timer;

const MenuList: FC<MenuProps> = ({
  triggerComponent,
  children,
  className,
  disabled = false,
  direction = 'right',
  minWidth = '150px',
  onClick,
}) => {
  const [isOpen, setOpen] = useState(false);
  const menuRef = useRef<ElementRef<'ul'>>(null);
  const menuTriggerRef = useRef<HTMLElement>(null);

  const showMenu = ({ e, delay }: OpenMenuEvent) => {
    e.stopPropagation();
    e.preventDefault();

    const menuRoot = menuTriggerRef.current as HTMLElement;
    const menu = menuRef!.current as HTMLElement;

    const target = e.currentTarget as HTMLSpanElement;
    const clientY = target.getBoundingClientRect().top;

    menu.style.visibility = 'hidden';
    menu.style.display = 'block';
    menu.style.top = `calc(${menuRoot.offsetHeight + clientY}px)`;

    if (direction === 'right') {
      menu.style.left = `${menuRoot.getBoundingClientRect().x}px`;
    } else {
      menu.style.left = `${
        menuRoot.getBoundingClientRect().x +
        menuRoot.offsetWidth -
        menu.offsetWidth
      }px`;
    }

    menu.style.visibility = '';
    menu.style.display = '';

    clearTimeout(menuEnterTimer);

    if (delay) {
      menuEnterTimer = setTimeout(() => {
        menu.setAttribute('data-is-open', 'true');
        setOpen(true);
      }, DELAY);
    } else {
      menu.setAttribute('data-is-open', 'true');
      setOpen(true);
    }
  };

  const hideMenu = () => {
    menuRef.current?.setAttribute('data-is-open', 'false');
    clearTimeout(menuEnterTimer);
    setOpen(false);
  };

  const selectFirstMenuItem = (e: ReactKeyboardEvent) => {
    if (e.key === 'ArrowDown') {
      e.stopPropagation();
      e.preventDefault();
      const menu = menuRef.current;
      const target = menu?.querySelector<HTMLElement>('a,[tabindex="0"]');
      target?.focus();
    }
  };

  const leaveAfterLastMenuItem = (e: ReactKeyboardEvent) => {
    const target = e.target as HTMLElement;
    const isLastChild = menuRef.current!.lastChild!.contains(target);
    if (e.key === 'Tab' && isLastChild) {
      hideMenu();
    }
  };

  const toggleMenu = ({ e, delay }: OpenMenuEvent) => {
    isOpen ? hideMenu() : showMenu({ e, delay });
  };

  useEffect(() => {
    if (!menuRef.current || !menuTriggerRef.current) return;

    const events = ['click', 'scroll', 'resize'];
    events.forEach((name) => window.addEventListener(name, hideMenu));

    return () => {
      events.forEach((name) => window.removeEventListener(name, hideMenu));
      clearTimeout(menuEnterTimer);
    };
  }, []);

  const listItems = Children.map(children, (child, index) =>
    cloneElement(child, {
      'data-index': index,
    }),
  );

  return (
    <div onMouseLeave={hideMenu}>
      {triggerComponent(
        disabled
          ? ({} as MenuTriggerProps)
          : {
              ref: menuTriggerRef,
              onKeyDown: selectFirstMenuItem,
              onClick: (e) => toggleMenu({ e, delay: false }),
              onMouseEnter: (e) => showMenu({ e, delay: true }),
            },
      )}
      <List
        ref={menuRef}
        className={'animate-grow-down ' + className}
        data-is-open="false"
        data-direction={direction}
        role="menu"
        onClick={onClick}
        tabIndex={-1}
        minWidth={minWidth}
        onKeyDown={leaveAfterLastMenuItem}
      >
        {listItems}
      </List>
    </div>
  );
};
//#endregion

export { MenuList, MenuItem };
