import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getActivePlan, updateActivePlan } from 'core/services/plan-service';
import { setHttpError } from './sharedSlice';
import { pickEmplid } from './utils';

const initialState: PlanActiveState = {
  loading: false,
  saving: false,
  noActivePlanFound: false,
  data: null,
};

export const planActiveSlice = createSlice({
  name: 'planActive',
  initialState,
  reducers: {
    setPlanActiveLoading: (state, action: ActionOf<boolean>) => {
      state.loading = action.payload;
    },
    setPlanActiveSaving: (state, action: ActionOf<boolean>) => {
      state.saving = action.payload;
    },
    setPlanActiveNotFound: (state, action: ActionOf<boolean>) => {
      state.noActivePlanFound = action.payload;
    },
    setActivePlan: (state, action: ActionOf<API.PlanData.Plan | null>) => {
      state.data = action.payload;
    },
  },
});

export const getActivePlanAsync = createAsyncThunk(
  'planSlice/getActivePlanAsync',
  async (
    {
      useSearchProfile,
    }: {
      useSearchProfile?: boolean;
    },
    { dispatch, getState },
  ) => {
    dispatch(setPlanActiveLoading(true));
    dispatch(setPlanActiveNotFound(false));
    dispatch(setActivePlan(null));
    try {
      const emplid = pickEmplid(getState(), useSearchProfile);
      const data = await getActivePlan(emplid);
      dispatch(setActivePlan(data.plan));
    } catch (error) {
      const res = error as HTTPError;

      if (res.response?.status === 404) {
        dispatch(setPlanActiveNotFound(true));
      } else {
        dispatch(
          setHttpError({
            httpError: res.message,
            sourceAction: getActivePlanAsync.typePrefix,
          }),
        );
      }
    }

    dispatch(setPlanActiveLoading(false));
  },
);

export const updateActivePlanAsync = createAsyncThunk(
  'planSlice/updateActivePlanAsync',
  async ({ planId }: { planId: string }, { dispatch, getState }) => {
    dispatch(setPlanActiveSaving(true));
    try {
      const emplid = pickEmplid(getState());
      const data = await updateActivePlan(emplid, planId);

      dispatch(setActivePlan(data.plan));
      dispatch(setPlanActiveNotFound(false));

      return { saved: true };
    } catch (error) {
      dispatch(
        setHttpError({
          httpError: (error as HTTPError).message,
          sourceAction: updateActivePlanAsync.typePrefix,
        }),
      );
      return { error, saved: false };
    } finally {
      dispatch(setPlanActiveSaving(false));
    }
  },
);

export const {
  setPlanActiveNotFound,
  setPlanActiveLoading,
  setPlanActiveSaving,
  setActivePlan,
} = planActiveSlice.actions;

export default planActiveSlice.reducer;
