import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { parseAsuTerm } from 'core/utils/date-utils';
import {
  cloneDraftObject,
  cloneObject,
} from 'core/utils/structured-clone-utils';
import {
  deletePlan,
  getPlanList,
  getPlanVersion,
  planPoller,
} from '../../services/plan-service';
import { setPlanSetup } from './planSetupSlice';
import { setHttpError } from './sharedSlice';
import { pickEmplid } from './utils';

const scrollContainerId = 'degree-plan';

const initialState: PlanState = {
  loadFail: false,
  saving: false,
  saved: false,
  loading: false,
  loaded: false,
  updating: false,
  dataModified: false,
  dataList: [],
  data: {
    uuid: '',
    isActivePlan: false,
    currentPlanVersion: 0,
    maxPlanVersion: 0,
    planName: '',
    lastModified: null,
    summary: {
      targetGraduationTerm: '',
      expectedGraduationTerm: '',
      degreeProgress: {
        currentMajor: {
          name: '',
          inProgressHours: 0,
          earnedHours: 0,
          remainingHours: 0,
        },
        targetMajor: {
          name: '',
          inProgressHours: 0,
          earnedHours: 0,
          remainingHours: 0,
        },
        totals: {
          inProgressHours: 0,
          earnedHours: 0,
          remainingHours: 0,
        },
        remainingUpperDivisionHours: 0,
        unusedHours: 0,
        cumulativeGPA: 0,
      },
      planInfo: {
        remainingHoursPlanned: {
          workDonePercent: 0,
          workLeftPercent: 0,
        },
      },
      notes: '',
    },
    transferCredits: {
      courseCredits: [],
      testCredits: [],
    },
    studentProgress: [],
    degreePlan: [],
  },
  dataCopy: null,
  dataHistory: {
    undoStack: [],
    redoStack: [],
    undoStackActive: false,
    redoStackActive: false,
    lastChangeType: null,
  },
  planTermGroups: [],
};

export const planSlice = createSlice({
  name: 'plan',
  initialState,
  reducers: {
    setPlanLoading: (state, action: ActionOf<boolean>) => {
      state.loading = action.payload;
      state.loaded = !action.payload;
    },
    setPlanSaving: (state, action: ActionOf<boolean>) => {
      state.saving = action.payload;
    },
    setPlanUpdating: (state, action: ActionOf<boolean>) => {
      state.updating = action.payload;
    },
    setPlanFail: (state, action: ActionOf<boolean>) => {
      state.loadFail = action.payload;
    },
    setPlanName: (state, action: ActionOf<string>) => {
      state.data.planName = action.payload;
    },
    setPlan: (state, action: ActionOf<API.PlanData.Plan>) => {
      state.data = action.payload;
      // backup data in case the user want to undo the changes
      state.dataCopy = cloneObject(action.payload);
      // group session by terms
      const { degreePlan } = action.payload;

      state.planTermGroups = degreePlan.map((term, index) => ({
        value: term.term || '',
        label: `Term ${term._sequenceIndex}: ${
          parseAsuTerm(term.term).yearSession
        }`,
        sessionNames: term.sessions
          .filter((s) => s.sessionName !== undefined)
          .map((s) => ({
            label: `Session ${s.sessionName}`,
            value: `${s.sessionName}`,
          })),
      }));
    },
    setPlanList: (state, action: ActionOf<API.PlanData.PlanRecord[]>) => {
      state.dataList = action.payload;
    },
    modifySessionClass: (state, action: ActionOf<ClassDragItemData>) => {
      moveClass(state, action);
    },
    resetPlanHistory: (state) => {
      state.dataHistory = initialState.dataHistory;
    },
    // TODO: once the WEB API to save Class change is ready, refactor this function to be backend driven
    revertPlanChanges: (
      state,
      { payload: { direction } }: ActionOf<{ direction: HistoryDirection }>,
    ) => {
      // //===========================================================
      const {
        isUndo,
        lastHistoryItem,
        pastStack,
        futureStack,
        undoStack,
        redoStack,
      } = readHistoryStacks(state.dataHistory, direction);
      //===========================================================
      if (!lastHistoryItem) {
        return;
      }
      //===========================================================
      const oldPlanSnapshot = state.data.degreePlan;
      // //===========================================================
      const { changeType, currentPlanVersion } = lastHistoryItem;

      // TODO: verify using the full snapshot improve performance
      // TODO: waiting for the auto save to be implemented
      // TODO: `state.data.degreePlan = planSnapshot;`

      pastStack.pop();
      let isBackendInSync = false;

      if (changeType === 'CLASS_MOVE') {
        const { classChange, scrollArea } = lastHistoryItem;
        const isClassMoveDone = revertClassMove({
          isUndo,
          state,
          classChange,
          scrollArea,
        });

        // Push the done action onto the redo stack
        if (isClassMoveDone) {
          futureStack.push({
            changeType,
            currentPlanVersion,
            planSnapshot: oldPlanSnapshot,
            classChange,
            scrollArea,
            isBackendInSync,
          });
        }
      } else if (changeType === 'SETUP_UPDATE') {
        const { setupChange } = lastHistoryItem;
        let newSetupChange = setupChange;

        newSetupChange = {
          newSetup: setupChange.oldSetup,
          oldSetup: setupChange.newSetup,
        };

        futureStack.push({
          changeType,
          currentPlanVersion,
          planSnapshot: oldPlanSnapshot,
          setupChange: newSetupChange,
          isBackendInSync,
        });
      }
      //===========================================================
      state.dataHistory.undoStackActive = undoStack.length > 0;
      state.dataHistory.redoStackActive = redoStack.length > 0;
      //===========================================================
    },
    // TODO: once the WEB API to save Class change is ready, refactor this function to be backend driven
    trackPlanChanges: (
      state,
      {
        payload: { changeType, setupChange, classChange, scrollArea },
      }: ActionOf<PlanHistoryInput>,
    ) => {
      state.dataHistory.undoStackActive = true;
      state.dataHistory.lastChangeType = changeType;

      const planSnapshot = cloneDraftObject<API.PlanData.Term[]>(
        state.data.degreePlan,
      );

      let { undoStack, redoStack } = state.dataHistory;
      let historyItem = {
        isBackendInSync: false,
        changeType,
        currentPlanVersion: state.data.currentPlanVersion,
        planSnapshot,
      } as PlanHistoryItem;

      if (changeType === 'CLASS_MOVE') {
        historyItem = {
          ...historyItem,
          classChange: classChange,
          scrollArea: scrollArea,
        } as PlanHistoryItem;
      } else if (changeType === 'SETUP_UPDATE') {
        historyItem = {
          ...historyItem,
          setupChange: setupChange,
        } as PlanHistoryItem;
      }
      //========================================================================
      // TODO: this a workaround to match how the backend works
      //========================================================================
      // Basically the backend does not handle a change made in the middle:
      // 5 VERSION
      // UNDO till you get version 3
      // Make a new plan change
      // Expected behavior is to have the new change after version 3
      // Instead is store after version 5
      // Temporary fix till all type of plan change will handle by the Backend
      // Eventually the entire workflow UNDO/Redo Requires a refactoring
      //========================================================================
      if (state.dataHistory.redoStack.length > 0) {
        undoStack.push(...redoStack);
        state.dataHistory.redoStack = [];
        state.dataHistory.redoStackActive = false;
      }
      //========================================================================
      undoStack.push(historyItem);
    },
  },
});

const readHistoryStacks = (
  dataHistory: DataHistory,
  direction: HistoryDirection,
) => {
  const { undoStack, redoStack } = dataHistory;
  let pastStack: PlanHistoryItem[], futureStack: PlanHistoryItem[];

  const isUndo = direction === 'undo';

  if (isUndo) {
    pastStack = undoStack;
    futureStack = redoStack;
  } else {
    pastStack = redoStack;
    futureStack = undoStack;
  }

  const lastHistoryItem = pastStack[pastStack.length - 1];

  return {
    isUndo,
    pastStack,
    futureStack,
    undoStack,
    redoStack,
    lastHistoryItem,
  };
};

const revertClassMove = ({
  isUndo,
  state,
  classChange,
  scrollArea,
}: {
  isUndo: boolean;
  state: PlanState;
  classChange: ClassDragItemData;
  scrollArea: PlanHistoryScrollArea;
}) => {
  const { source, target } = classChange!;
  //===========================================================
  // Move class inside the plan board
  //===========================================================
  let isClassMoveDone = false;

  if (isUndo) {
    isClassMoveDone = moveClass(state, {
      payload: {
        source: {
          ...target,
          classUId: source.classUId,
          classType: source.classType,
        },
        target: {
          ...source,
        },
      },
    });
  } else {
    isClassMoveDone = moveClass(state, {
      payload: {
        source,
        target,
      },
    });
  }
  //===========================================================
  // UI: Class drop Transition
  //===========================================================

  // # Scroll to source term container
  const { top, left } = isUndo ? scrollArea.start : scrollArea.end;
  const scrollContainer = document.querySelector(`#${scrollContainerId}`);
  scrollContainer?.scrollTo({
    top,
    left,
    behavior: 'smooth',
  });

  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });

  // # Animate drop
  const { classUId } = source;
  const sessionUId = isUndo ? source.sessionUId : target.sessionUId;
  setTimeout(() => {
    const targetElements = document.querySelectorAll(
      `#${sessionUId},#${classUId}`,
    );
    targetElements.forEach((targetElement) =>
      targetElement.setAttribute('data-drag-revert', 'true'),
    );

    setTimeout(() => {
      targetElements.forEach((targetElement) =>
        targetElement.removeAttribute('data-drag-revert'),
      );
    }, 200);
  }, 500);
  //===========================================================
  return isClassMoveDone;
};

/**
 * This function update the plan state in 2 different use cases:
 * CASE 1: user Drag & Drop a class from one term/session to another
 * CASE 2: user click UNDO/REDO to revert a class move
 * @returns Indicate move has be done
 */
const moveClass = (
  state: PlanState,
  { payload }: ActionOf<ClassDragItemData>,
): boolean => {
  const { target, source } = payload;
  const planTerms = state.data.degreePlan;
  const sourceListType: Record<
    API.PlanData.ClassType,
    'classes' | 'requiredClasses' | 'selectedClasses'
  > = {
    enrolled: 'classes',
    required: 'requiredClasses',
    selected: 'selectedClasses',
  };
  const SOURCE_LIST_TYPE = sourceListType[source.classType] || 'classes';
  //===========================================================
  // source
  //===========================================================
  const sourceTerm = planTerms.find((term) => term._uid === source.termUId);
  const sourceSession = sourceTerm?.sessions.find(
    (sessions) => sessions._uid === source.sessionUId,
  )!;
  const sourceClasses = sourceSession?.[SOURCE_LIST_TYPE] || [];

  const classItem = sourceClasses.find(
    (sClass) => sClass._uid === source.classUId,
  );

  //===========================================================
  // EDGE CASE: USER action conflicts REDO action
  //===========================================================
  // 1. Move a class-x FROM Term-x/Session-x TO Term-y/Session-y
  // 2. Click UNDO
  // 3. Repeat step 1
  // 4. Click REDO
  // Step 4 creates a history conflict use the user manual action,
  // and eventually it would throws an error.
  //===========================================================
  // FIX: if a class is not found inside the `source` history,
  // the action will be considered already executed.
  //===========================================================
  if (!classItem) return false;
  //===========================================================

  // remove source class item
  sourceSession[SOURCE_LIST_TYPE] = sourceClasses.filter(
    (sClass) => sClass._uid !== source.classUId,
  );
  //===========================================================
  // target
  //===========================================================
  const targetTerm = planTerms.find((term) => term._uid === target.termUId);
  const targetSession = targetTerm?.sessions.find(
    (session) => session._uid === target.sessionUId,
  )!;

  // add class item to target
  targetSession[SOURCE_LIST_TYPE]?.push(classItem);
  //===========================================================
  state.dataModified = true;
  return true;
};

export const revertPlanChangesAsync = createAsyncThunk(
  'planSlice/revertPlanChangesAsync',
  async (
    payload: {
      planId: string;
      direction: HistoryDirection;
    },
    { dispatch, getState },
  ) => {
    dispatch(setPlanLoading(true));
    try {
      const { planId, direction } = payload;
      const emplid = pickEmplid(getState());

      const { plan, setup } = await getPlanVersion(emplid, planId, direction);

      plan && dispatch(setPlan(plan));
      setup && dispatch(setPlanSetup(setup));

      dispatch(
        revertPlanChanges({
          direction,
        }),
      );
    } catch (error) {
      dispatch(
        setHttpError({
          httpError: (error as HTTPError).message,
          sourceAction: revertPlanChangesAsync.typePrefix,
        }),
      );
    }
    dispatch(setPlanLoading(false));
  },
);

export const getPlanListAsync = createAsyncThunk(
  'planSlice/getPlanListAsync',
  async (payload, { dispatch, getState }) => {
    dispatch(setPlanLoading(true));
    try {
      const emplid = pickEmplid(getState());
      const dataList = await getPlanList(emplid);
      dispatch(setPlanList(dataList));
    } catch (error) {
      dispatch(
        setHttpError({
          httpError: (error as HTTPError).message,
          sourceAction: getPlanListAsync.typePrefix,
        }),
      );
    }
    dispatch(setPlanLoading(false));
  },
);

export const getPlanAsync = createAsyncThunk(
  'planSlice/getPlanAsync',
  async (payload: { planId: string }, { dispatch, getState }) => {
    dispatch(setPlanLoading(true));
    dispatch(setPlanFail(false));

    try {
      const { planId } = payload;
      const emplid = pickEmplid(getState());

      if (!planId) {
        dispatch(setPlanLoading(false));
        dispatch(setPlanFail(true));
        return;
      }

      // start poller
      planPoller({
        emplid,
        planId,
        onSuccess: async (data) => {
          data.plan && dispatch(setPlan(data.plan));
          data.setup && dispatch(setPlanSetup(data.setup));
          dispatch(setPlanLoading(false));
        },
        onError: async (error: any) => {
          // TODO: to be refactored
          console.error(error);
          alert('Server Error. Plan data could not be retrieved.');
          dispatch(setPlanLoading(false));
          dispatch(setPlanFail(true));
        },
      });
    } catch (error) {
      dispatch(
        setHttpError({
          httpError: (error as HTTPError).message,
          sourceAction: getPlanAsync.typePrefix,
        }),
      );
      dispatch(setPlanLoading(false));
    }
  },
);

// TODO: to be refactored with the WEB API integration
export const modifySessionClassAsync = createAsyncThunk(
  'planSlice/modifySessionClassAsync',
  async (payload: ClassDragItemData, { dispatch, getState }) => {
    dispatch(setPlanUpdating(true));

    // TODO: update `modifySessionClass` once the update plan WEB API is ready

    try {
      // TODO: call the web api to update the plan
    } catch (error) {
      dispatch(
        setHttpError({
          httpError: (error as HTTPError).message,
          sourceAction: modifySessionClassAsync.typePrefix,
        }),
      );
      return { error, saved: false };
    } finally {
      dispatch(setPlanUpdating(false));
    }
  },
);

export const deletePlanAsync = createAsyncThunk(
  'planSlice/deletePlanAsync',
  async ({ planId }: { planId: string }, { dispatch, getState }) => {
    try {
      const emplid = pickEmplid(getState());
      const res = await deletePlan(emplid, planId);

      return res;
    } catch (error) {
      dispatch(
        setHttpError({
          httpError: (error as HTTPError).message,
          sourceAction: deletePlanAsync.typePrefix,
        }),
      );
      return false;
    }
  },
);

export const {
  setPlanLoading,
  setPlanName,
  setPlan,
  setPlanList,
  setPlanSaving,
  setPlanUpdating,
  setPlanFail,
  modifySessionClass,
  revertPlanChanges,
  resetPlanHistory,
  trackPlanChanges,
} = planSlice.actions;

export { scrollContainerId };

export default planSlice.reducer;
