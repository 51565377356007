// @ts-check
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// local services
import { useAppDispatch, useAppSelector } from 'core/store';
import {
  AuthError,
  OidcAuthClientService,
} from '../sso';
import { authSettings } from '../sso/constants/auth-settings';
import { recoverState } from '../sso/services/auth-local-state-service';
import { actionTypes, logout as logoutAction, recoverAuthState, setClaim, setUserRoles, storeAuthState } from '../store';
import { formatLog, shortLog } from '../utils/logger-utils';
import { AuthContext } from '.';

/**
 * @typedef {{
 *    jwt: AppJwtToken | undefined,
 *    action: "CREATE_TOKEN" | "REFRESH_TOKEN"
 * }} AuthTokenEvent
 * @typedef {( event: AuthTokenEvent) => Promise<void> | void } AuthTokenEventHandler
 */

/**
 * @template TClaim
 *
 * @param {PropsWithChildren<{
 *  authProps: Partial<AuthContextValue<TClaim>>
 *  onTokenUpdate?: AuthTokenEventHandler
 * }>} props
 */
export function AuthProvider({ children, authProps = {}, onTokenUpdate }) {
  console.log(...shortLog(`Auth Provider component...`));
  let navigate = useNavigate();

  const dispatch = useAppDispatch();
  const state = useAppSelector(
    (state) => state.auth,
  );

  const didInitialize = useRef(false);

  const { customSettings, getUserRoles, Loader } = authProps;

  const [client] = useState(
    new OidcAuthClientService({ ...authSettings, ...customSettings })
  );

  useEffect(() => {
    if (didInitialize.current) {
      return;
    }
    didInitialize.current = true;

    (async () => {
      if (state.errorBlocker) {
        return;
      }

      console.log(
        ...formatLog({
          action: 'Mount',
          message: `Checking for auth params`,
        }),
      );

      try {
        const localState = recoverState();
        // =====================================================================
        if (localState?.isAuthenticated) {
          dispatch(recoverAuthState());
        }
        // =====================================================================
      } catch (error) {
        console.log(
          ...formatLog({
            action: 'Error',
            message: `Checking for auth params`,
          }),
          error,
        );

        dispatch({
          type: actionTypes.ERROR,
          error,
          errorBlocker: true,
        });

        if (
          error instanceof AuthError ||
          // @ts-ignore
          error.message.includes('status code 400')
        ) {
          navigate('/');
        }
      }
    })();
  }, [
    client,
    state.errorBlocker,
    state.isAuthenticated,
    navigate,
    onTokenUpdate,
    dispatch,
  ]);

  const loadUserRoles = useCallback(async () => {
    if (!getUserRoles) return;

    dispatch(
      setUserRoles({
        loading: true,
        completed: false,
      })
    );

    const roles = await getUserRoles();

    dispatch(
      setUserRoles({
        roles,
        loading: false,
        completed: true,
      })
    );
  }, [getUserRoles, dispatch]);

  const loginWithRedirect = useCallback(
    () => client.loginWithRedirect(),
    [client],
  );

  const logout = useCallback(() => {
    client.logout();
    dispatch(logoutAction());
  }, [client, dispatch]);

  return (
    <AuthContext.Provider
      value={
        /** @type {AuthContextValue} */
        ({
          state,
          customSettings,
          loginWithRedirect,
          logout,
          loadUserRoles,
          getUserRoles,
          Loader,
        })
      }
    >
      {children}
    </AuthContext.Provider>
  );
}
