import { fetchData } from 'core/utils/http-utils';

const BASE_URL = '/api/terms';

async function getGraduationTermOptions(): Promise<OptionItem[]> {
  const { data } = await fetchData<API.HttpGradTermGetResponse>(BASE_URL);

  // Filter out summer terms for graduation term options
  return data
    .filter((gradTerm) => !gradTerm.strm.endsWith('4'))
    .map((gradTerm) => ({
      label: gradTerm.termName,
      value: gradTerm.termName,
    }));
}

export { getGraduationTermOptions };
