import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import loggedOutLogo from 'img/asu-logo/asu-sunburst-maroon-gold.png';

const Container = styled.section`
  position: relative;
  height: calc(100vh - var(--scroll-margin-top));
  display: grid;
  align-items: center;
  justify-content: center;

  & .content {
    &:before {
      content: '';
      position: absolute;
      inset: 0;
      padding: 2rem;
      background-color: #fff;
      border-radius: 15px;
      filter: blur(5px);
      opacity: 0.43;
      z-index: -1;
    }

    padding: 2rem;
    position: relative;
  }

  & * {
    text-align: center;
    line-height: 1.1;
  }

  h2 {
    font-size: 4rem;
  }
  h3 {
    font-size: 2.5rem;
  }

  &:before {
    background: url(${loggedOutLogo});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    object-fit: cover;
    /* filter: grayscale(); */
    opacity: 0.06;

    width: 100%;
    height: 80%;

    content: '';
    position: absolute;
    z-index: -1;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

type DummyPlaceholderProps = {
  children?: ReactNode;
};

const DummyPlaceholder: FC<DummyPlaceholderProps> = ({ children }) => {
  return (
    <Container>
      <div className="content">
        <h2>{children}</h2>
        <h3>Under Construction</h3>
      </div>
    </Container>
  );
};

export { DummyPlaceholder };
