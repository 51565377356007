type ParamDate = ISODate | ISODateTime | number;

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

function splitDate(isoDate: ISODate | ISODateTime): Partial<{
  M: number;
  MM: string;
  MMM: string;
  MMMM: string;
  d: string;
  dd: string;
  YYYY: string;
  YY: string;
}> & {
  validDate: boolean;
} {
  if (!isoDate)
    return {
      validDate: false,
    };

  const dt = new Date(isoDate);
  if (String(dt) === 'Invalid Date')
    return {
      validDate: false,
    };

  //  =================================================
  const fullYear = String(dt.getFullYear());
  const monthIndex = dt.getMonth();
  const day = dt.getDate();

  //  =================================================
  const monthName = months[monthIndex];
  const monthNumber = monthIndex + 1;
  //  =================================================
  // Examples below given for 2023-08-06T13:07:04.054
  const dateElements = {
    M: monthNumber, // 8
    MM: String((monthNumber < 10 ? '0' : '') + monthNumber), // 08
    MMM: monthName.substring(0, 3), // Aug
    MMMM: monthName, // August
    d: String(day), // 6
    dd: String((day < 10 ? '0' : '') + day),
    // 06
    YYYY: fullYear, // 2023
    YY: fullYear.substring(2), // 23,
    validDate: true,
  };

  return dateElements;
}

function parseDateUTC(inputDate: ParamDate) {
  let wkDate = inputDate;

  if (typeof inputDate === 'string') {
    wkDate = fromIsoToUTC(inputDate);
  }

  return wkDate;
}

function fromIsoToUTC(isoDate: ISODate | ISODateTime) {
  const defaultValue = 0;
  return Date.UTC(
    parseInt(isoDate.slice(0, 4)) || defaultValue, // year
    parseInt(isoDate.slice(5, 7)) - 1 || defaultValue, // month (0-based)
    parseInt(isoDate.slice(8, 10)) || defaultValue, // day
    parseInt(isoDate.slice(11, 13)) || defaultValue, // hour
    parseInt(isoDate.slice(14, 16)) || defaultValue, // minute
    parseInt(isoDate.slice(17, 19)) || defaultValue, // second
    parseInt(isoDate.slice(20, 23)) || defaultValue, // millisecond
  );
}

function toDateTimeWithFixedDate(timeString: string) {
  return new Date(Date.parse(`1970-01-01T${timeString}:00.000Z`));
}

type MonthDay = `${string} ${string}`;
const toDateWithMonthDay = (inputDate?: ParamDate): MonthDay | '' => {
  if (!inputDate) return '';

  const wkDate = parseDateUTC(inputDate);

  const date = new Date(wkDate);
  const month = date
    .toLocaleString('default', { month: 'short', timeZone: 'UTC' })
    .toUpperCase();
  const day = date.getUTCDate().toString().padStart(2, '0');
  const formattedDate: MonthDay = `${month} ${day}`;

  return formattedDate;
};

type DateWithTimeSetting = { separator: string; midJoinWord: string };
const toDateWithTime = (
  inputDate: ParamDate,
  settings = {} as Partial<DateWithTimeSetting>,
) => {
  if (!inputDate) return '';

  const wkDate = parseDateUTC(inputDate);

  const { separator, midJoinWord } = {
    separator: '/',
    midJoinWord: ' ',
    ...settings,
  };

  const date = new Date(wkDate);

  const YY = date.getUTCFullYear();
  const MM = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const DD = date.getUTCDate().toString().padStart(2, '0');

  const formattedDate = `${DD}${separator}${MM}${separator}${YY}`;

  const formattedTime = date
    .toLocaleString('default', {
      hour12: true,
      timeStyle: 'short',
      timeZone: 'UTC',
    })
    .replace(' ', '');

  return `${formattedDate}${midJoinWord}${formattedTime}`;
};

/**
 * @param sTerm - 4 digits number `strm` format
 */
const parseAsuTerm = (sTerm?: API.AsuSTerm) => {
  if (!sTerm) {
    return {
      session: '',
      year: '',
      sessionYear: '',
      yearSession: '',
    };
  }

  if (sTerm.length !== 4)
    throw new Error(
      `The strm "${sTerm}" you provided is incorrect.\n` +
        `It must be 4 digits string`,
    );

  const terms: Record<number, string> = {
    1: 'Spring',
    4: 'Summer',
    7: 'Fall',
  };
  // convert to array, add "0" at index `1`
  const digits = sTerm.split('');
  digits.splice(1, 0, '0');
  // flat the array to a string
  const fullString = digits.join('');
  // extract year
  const year = fullString.substring(0, 4);
  // extract sessionCode
  const sessionCode = Number(fullString.substring(4));
  const session = terms[sessionCode] || '';

  // create response. Example: a param strm: "2237" => "Fall 2023"
  const sessionYear = `${session} ${year}`;
  // create response. Example: a param strm: "2237" => "2023 Fall"
  const yearSession = `${year} ${session}`;

  return {
    session,
    year,
    sessionYear,
    yearSession,
  };
};

const validateTimeRange = (
  startTime: string,
  endTime: string,
  invalidateEquality = true,
) => {
  let isValid = true;
  const dtStartTime = toDateTimeWithFixedDate(startTime);
  const dtEndTime = toDateTimeWithFixedDate(endTime);

  if (startTime || endTime) {
    isValid = dtStartTime < dtEndTime;

    if (
      !invalidateEquality &&
      dtStartTime.toString() === dtEndTime.toString()
    ) {
      isValid = true;
    }
  }

  return isValid;
};

/**
 * @example toTimeFormat12h("23:00")  // Output: "11:00 PM"
 *
 * @param {string} timeFormat24h
 * @return {string}
 */
function toTimeFormat12h(timeFormat24h: string) {
  if (!timeFormat24h || timeFormat24h.length < 5) return '';

  let hours = Number(timeFormat24h.split(':')[0]);
  let minutes = timeFormat24h.split(':')[1];
  let period = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12;
  return `${hours}:${minutes} ${period}`;
}

/**
 * TODO: To do double check. Right now does not work in my machine
 * Check the O.S. reginal settings time format, if is 12H format
 * return `true` otherwise `false`
 */
// function isOSTimeFormat12h() {
//   const res = new Intl.DateTimeFormat().resolvedOptions().hour12;
//   return res;
// }

export {
  toDateWithTime,
  toDateWithMonthDay,
  toDateTimeWithFixedDate,
  toTimeFormat12h,
  parseAsuTerm,
  validateTimeRange,
  splitDate,
};
