const demoUser: Omit<
  API.StudentData.Profile,
  'preferredCommunication' | 'declaredDegreePrograms' | 'advisors'
> = {
  asurite: 'eperez555',
  emplid: '1232084557',
  firstName: 'Esmerelda',
  lastName: 'Perez',
  preferredName: 'Ezzy Perez',
  email: 'Ezzy.Perez@gmail.com',
  phone: '602-999-1234',
};

export { demoUser };
