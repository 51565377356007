import { FC } from 'react';
import { timeZones } from 'core/model/time-zones';
import { Dropdown } from '../Dropdown';

type TimezoneFieldProps = GenericFieldProps & {
  onChange: (value: string) => void;
};

const TimezoneField: FC<TimezoneFieldProps> = ({
  label,
  value,
  placeholder,
  error,
  style = {},
  onChange,
}) => {
  const selectedValue = timeZones.find((item) => item.value === value);

  return (
    <div className="form-group" style={style}>
      <label>{label}</label>
      <Dropdown
        value={selectedValue}
        options={timeZones}
        placeholder={placeholder}
        onChange={(selectedItem) => {
          onChange((selectedItem as OptionItem).value);
        }}
      />
      {error && (
        <small className="form-text invalid-feedback">
          <span
            title="Alert"
            className="fa fa-icon fa-exclamation-triangle"
          ></span>
          {error}
        </small>
      )}
    </div>
  );
};

export { TimezoneField };
