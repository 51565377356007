import partnerConfig from '../../partner-config.json';
import { appRouterUrl } from './router-url';

const env = process.env;
// ============================================================================
// webapp auth settings
// ============================================================================
const defaultLoginUrl = '/login';
const defaultRedirectUrl = window.location.origin;
const defaultClientId = `interactive-degree-planner-public-nonprod`;
// ============================================================================
// scopes
// ============================================================================
const defaultScopes = [
  'https://interactive-degree-planner-nonprod.apps.asu.edu/authz:self',
];
const dplScopes = [
  'https://api.myasuplat-dpl.asu.edu/scopes/person/read:self',
  'https://api.myasuplat-dpl.asu.edu/scopes/principal/read:self',
  'https://api.myasuplat-dpl.asu.edu/scopes/phone/read:self',
  'https://api.myasuplat-dpl.asu.edu/scopes/acad-plan/read:self',
];
// merge environment scopes with the mandatory dplScopes
const scopeSet = new Set([
  ...(partnerConfig?.cognito_scopes.split(' ') || defaultScopes),
  // ...dplScopes,
]);
// ============================================================================
const authSettings: AuthAppSettings = {
  loginUrl: (partnerConfig?.cognito_authorization_url + '/oauth2/authorize')
    || defaultLoginUrl,
  redirectUrl: env.REACT_APP_REDIRECT_URL
    || defaultRedirectUrl,
  clientId: partnerConfig?.cognito_app_client_id
    || defaultClientId,
  metadataUrl: partnerConfig?.cognito_metadata_url
    || '',
  scopes: Array.from(scopeSet),
  notAuthorizedUrl: appRouterUrl.NOT_AUTHORIZED,
};
// ============================================================================

export { authSettings };
