import { FC } from 'react';
import styled from 'styled-components';

const BackgroundContainer = styled.div`
  --spinner-color: var(--gray-8);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &[data-full-size='true'],
  &[data-attach-container='element'] {
    background-color: rgb(255 255 255);
    opacity: 0.7;
    color: #000;

    width: 100%;
    height: 100%;

    position: absolute;
    z-index: 10;
    left: 0;
    top: 0;
  }

  &[data-full-opacity='true'] {
    opacity: 1;
  }

  &[data-full-size='true'] {
    width: 100vw;
  }

  #wheel-spinner {
    border: 4px solid #fcd779;
    border-top: 4px solid white;
    border-radius: 50%;
  }
  // animation
  #wheel-spinner,
  #circle-spinner {
    transition-property: transform;
    animation: rotate;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  circle {
    fill: var(--spinner-color);
  }

  #text-spinner {
    color: var(--spinner-color);
    text-align: center;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const WheelTemplate = ({ className = '', width = '40px', height = '40px' }) => (
  <div
    id="wheel-spinner"
    className={className}
    style={{
      width,
      height,
    }}
  />
);

//
const CircleTemplate = ({
  className = '',
  width = '48px',
  height = '48px',
}) => (
  <svg
    id="circle-spinner"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 48 48"
  >
    <circle cx="24" cy="4" r="4" fill="#fff" />
    <circle cx="12.19" cy="7.86" r="3.7" fill="#fffbf2" />
    <circle cx="5.02" cy="17.68" r="3.4" fill="#fef7e4" />
    <circle cx="5.02" cy="30.32" r="3.1" fill="#fef3d7" />
    <circle cx="12.19" cy="40.14" r="2.8" fill="#feefc9" />
    <circle cx="24" cy="44" r="2.5" fill="#feebbc" />
    <circle cx="35.81" cy="40.14" r="2.2" fill="#fde7af" />
    <circle cx="42.98" cy="30.32" r="1.9" fill="#fde3a1" />
    <circle cx="42.98" cy="17.68" r="1.6" fill="#fddf94" />
    <circle cx="35.81" cy="7.86" r="1.3" fill="#fcdb86" />
  </svg>
);

type SpinnerLoaderProps = {
  spinnerType?: 'circle' | 'wheel';
  spinnerMessage?: string | string[] | React.ReactElement;
  width?: string;
  height?: string;
  attachContainer?: 'page' | 'element';
  fullSize?: boolean;
  fullOpacity?: boolean;
};

const parseSpinnerMessage = (
  spinnerMessage: SpinnerLoaderProps['spinnerMessage'],
) => {
  // array of string
  if (Array.isArray(spinnerMessage)) {
    return (
      <p id="text-spinner">
        {spinnerMessage.map((msg, index) => (
          <span key={msg}>
            {msg}
            <br />
          </span>
        ))}
      </p>
    );
  }
  // react element
  if (typeof spinnerMessage === 'object') {
    return spinnerMessage;
  }
  // plain string
  return <p id="text-spinner">{spinnerMessage}</p>;
};

const SpinnerLoader: FC<SpinnerLoaderProps> = ({
  spinnerType = 'circle',
  spinnerMessage = [],
  width = '20px',
  height = '20px',
  fullSize,
  fullOpacity,
  attachContainer = 'page',
}) => {
  const marginClass = spinnerMessage ? 'mb-4' : '';

  return (
    <BackgroundContainer
      id="container"
      data-full-size={fullSize}
      data-attach-container={attachContainer}
      data-full-opacity={fullOpacity}
    >
      {spinnerType === 'wheel' && (
        <WheelTemplate className={marginClass} width={width} height={height} />
      )}
      {spinnerType === 'circle' && (
        <CircleTemplate className={marginClass} width={width} height={height} />
      )}
      {parseSpinnerMessage(spinnerMessage)}
    </BackgroundContainer>
  );
};

export { SpinnerLoader };
