import { FC } from 'react';
import styled from 'styled-components';

import { roleList } from 'core/constants/app-constants';
import { Chip } from '../Chip';
import { Dropdown } from '../Dropdown';

const Container = styled.section`
  width: 100%;
  height: 60px;

  position: fixed;
  bottom: 0;
  z-index: 2000;

  background: var(--gray-2) 0% 0% no-repeat padding-box;
  box-shadow: 0px -3px 6px #00000029;

  display: flex;
`;

const Content = styled.div`
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .role-switch-left-section {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
  }
`;

const RoleSelect = styled.span`
  #role-select {
    width: 230px;

    .react-select-indicators-container *,
    .react-select-single-value,
    .react-select-place-holder,
    .react-select-input {
      color: #fff;
    }

    .react-select-indicators-container .react-select-indicator-separator {
      width: 0;
    }

    .react-select-control {
      background: var(--gray-7) 0% 0% no-repeat padding-box;
      border-radius: 25px;
      padding-inline: 1rem;
      font-weight: bold;
      border-color: #747474;
    }
  }
`;

type RoleSwitchProps = {
  userName: string;
  currentRole?: UserProfileRole;
  roles?: string[];
  onRoleChange: (roleName: UserProfileRole) => void;
  onResumeUser: () => void;
};

const RoleSwitch: FC<RoleSwitchProps> = ({
  userName,
  currentRole,
  roles = roleList,
  onRoleChange,
  onResumeUser,
}) => {
  const roleOptions = roles?.map(
    (role) =>
      ({
        label: role,
        value: role,
      }) as OptionItem<UserProfileRole>,
  );

  return (
    <Container>
      <Content className="container">
        <div className="role-switch-left-section">
          <span>
            <i className="fa-solid fa-mask" />
          </span>
          <span>
            Impersonating <strong>{userName}</strong> as a(n)
          </span>
          <RoleSelect>
            <Dropdown
              id="role-select"
              menuPlacement="top"
              isClearable={false}
              value={currentRole}
              options={roleOptions}
              onChange={(e) => {
                onRoleChange(e.value as UserProfileRole);
              }}
            />
          </RoleSelect>
        </div>

        <Chip
          bold
          type="button-chip"
          role="button"
          tabIndex={0}
          onClick={onResumeUser}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.currentTarget.click();
            }
          }}
        >
          <i className="fa-solid fa-xmark" />
          <span>Resume as self</span>
        </Chip>
      </Content>
    </Container>
  );
};

export { RoleSwitch };
