const RoleName = {
  STUDENT: 'student',
  ADVISOR: 'advisor',
  COACH: 'success-coach',
  ADMIN: 'admin',
  PLATFORM: 'platform-admin',
} as const;

const roleList = Object.values(RoleName);

const MOBILE_BREAK_POINT = '840px';

const HttpCode = {
  SUCCESS_CODE: 200,
  IN_PROGRESS_CODE: 202,
} as const;

export { HttpCode, RoleName, roleList, MOBILE_BREAK_POINT };
