import { maskDemoUsersResponse } from 'dev/demo-service';
import { fetchData, postData } from '../utils/http-utils';
import { getViewAsPayload } from './user-view-as-service';

const JWT_IDP_KEY = 'apps.asu.edu.IDP.token';
const JWT_CLAIM_IDP_KEY = 'apps.asu.edu.IDP.token.claim';

async function getUserRoles() {
  try {
    const data = await requestIDPAuthToken();
    const roles = data?.role ?? [];

    return {
      roles,
    };
  } catch (err) {
    console.error('[getUserRoles]', err);
    return {
      roles: [],
    };
  }
}

async function getIDPAuthToken() {
  const token = localStorage.getItem(JWT_IDP_KEY);

  return { token };
}

function getIDPClaimToken(): API.Token | null {
  const rawData = localStorage.getItem(JWT_CLAIM_IDP_KEY);
  const data = rawData ? (JSON.parse(rawData) as API.Token) : null;

  return data;
}

async function requestIDPAuthToken() {
  const URL = '/token';
  try {
    const payload = getViewAsPayload();
    const { data } = await postData<API.HttpTokenPostResponse>(URL, payload, {
      headers: {
        'Use-ServiceAuth-Token': true,
      },
    });

    setIDPAuthToken(data);

    return data;
  } catch (err) {
    console.error('[requestIDPAuthToken]', err);
  }
  return null;
}

async function searchUsers(searchBy: string) {
  const URL = `/api/users?search=${searchBy}`;
  const response = await fetchData<API.HttpUserSearchGetResponse>(URL);
  // TODO: comment after demo
  maskDemoUsersResponse(response);

  const { data } = response;

  if (Array.isArray(data)) {
    return data;
  }

  return [data];
}

function setIDPAuthToken(tokenData: API.Token) {
  localStorage.setItem(JWT_CLAIM_IDP_KEY, JSON.stringify(tokenData));
  localStorage.setItem(JWT_IDP_KEY, tokenData.access_token);
}

function clearUserTokens() {
  localStorage.removeItem(JWT_CLAIM_IDP_KEY);
  localStorage.removeItem(JWT_IDP_KEY);
}

export {
  getUserRoles,
  getIDPClaimToken,
  getIDPAuthToken,
  requestIDPAuthToken,
  searchUsers,
  setIDPAuthToken,
  clearUserTokens,
};
