// @ts-check

import { createSlice } from '@reduxjs/toolkit';
import { recoverState, storeState } from '../sso/services/auth-local-state-service';
import { formatLog } from '../utils/logger-utils';

export const actionTypes = /** @type {const} */ ({
  RETURN_URL: 'RETURN_URL',
  INITIALIZED: 'INITIALIZED',
  SET_AUTHENTICATED: 'SET_AUTHENTICATED',
  SET_CLAIM: 'SET_CLAIM',
  SET_USER_ROLES: 'SET_USER_ROLES',
  SET_LOADING: 'SET_LOADING',
  LOGOUT: 'LOGOUT',
  ERROR: 'ERROR',
});

/**
 * @type {AuthState}
 */
export const initialAuthState = {
  isUninitialized: true,
  isAuthenticated: false,
  error: undefined,
  user: '',
  isLoading: false,
  errorBlocker: false,
  userRoles: {
    loading: false,
    completed: false,
    roles: [],
  },
  ssoName: '',
  claim: {},
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    setAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload ?? false;
      state.isLoading = false;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setInitialized: (state, action) => {
      state.isUninitialized = false;
      state.user = action.payload.user;
      state.ssoName = action.payload.ssoName;
      state.returnUrl = action.payload.returnUrl;
      state.error = undefined;
      state.isLoading = false;
    },
    setClaim: (state, action) => {
      state.claim = action.payload?.claim || {};
    },
    setUserRoles: (state, action) => {
      state.userRoles = {
        ...state.userRoles,
        ...action.payload,
      }
    },
    logout: (state) => {
      state.isUninitialized = true;
      state.isAuthenticated = false;
      state.user = undefined;
      state.error = undefined;
      state.errorBlocker = false;
    },
    setError: (state, action) => {
      state.errorBlocker = !!action.payload.errorBlocker;
      state.error = action.payload.error;
      state.isLoading = false;
    },
    storeAuthState: (state) => {
      storeState(state);
    },
    recoverAuthState: (state) => {
      const storedState = recoverState();
      state.isAuthenticated = storedState.isAuthenticated;
    },
  }
});

export const {
  setAuthenticated,
  setLoading,
  setInitialized,
  setClaim,
  setUserRoles,
  logout,
  setError,
  storeAuthState,
  recoverAuthState,
} = authSlice.actions;

export default authSlice.reducer;
