// @ts-check
import { Suspense, useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

// core
import { useAppDispatch, useAppSelector } from 'core/store';
import { AuthContext, useAuthContext } from '../context';
import { recoverState } from '../sso/services/auth-local-state-service';
import { recoverAuthState } from '../store';
import { shortLog } from '../utils/logger-utils';

/**
 * @param {{ message: string }} props
 * @returns {React.ReactNode}
 */
const AuthLoaderFallback = ({ message }) => <div>{message}</div>;

const NotAuthorizedFallback = () => (
  <section>
    <h2 style={{ width: 'fit-content', display: 'flex', marginInline: 'auto' }}>
      You are not authorized.
    </h2>
  </section>
);

/**
 * @param {{
 *  children: React.ReactNode
 *  roles?: string []
 *  lazy?: boolean
 * }} props
 */
const ProtectedRoute = ({ children, roles = [], lazy = false }) => {
  console.log(...shortLog(`validate page permission in progress...`));

  const dispatch = useAppDispatch();
  const {
    customSettings: { notAuthorizedUrl },
    loadUserRoles,
    loginWithRedirect,
    Loader,
  } = useAuthContext();
  const appState = useAppSelector(
    (state) => state.auth,
  );
  const localState = recoverState();

  const roleValidateRequired = loadUserRoles && roles.length > 0;
  const rolePending =
    appState.userRoles?.loading
    || !appState.userRoles?.completed;
  const roleAuthorized =
    roles.some((role) => appState.userRoles?.roles?.includes(role));

  /** @type {React.ReactNode} */
  let Page;
  let AuthLoader = Loader ?? AuthLoaderFallback;

  useEffect(() => {
    if (appState.errorBlocker) {
      console.log(...shortLog(`Blocking error. Check the network tab.`));
      return;
    }

    if (appState.isLoading) {
      console.log(...shortLog(`either loading or user has valid auth`));
      return;
    }

    if (!appState.isAuthenticated && localState.isAuthenticated) {
      dispatch(recoverAuthState())
    }

    if (!appState.isAuthenticated && !localState.isAuthenticated) {
      loginWithRedirect();
    } else if (roleValidateRequired) {
      loadUserRoles();
    }
  }, [
    roleValidateRequired,
    appState.errorBlocker,
    appState.isAuthenticated,
    appState.isLoading,
    localState.isAuthenticated,
    loadUserRoles,
    loginWithRedirect,
    dispatch,
  ]);

  if (!appState.isAuthenticated && !localState.isAuthenticated) {
    Page = <AuthLoader message="Redirecting you to the login page..." />;
  } else if (roleValidateRequired && rolePending) {
    Page = <AuthLoader message="Checking your permissions..." />;
  } else if (roleValidateRequired && !roleAuthorized) {
    Page = notAuthorizedUrl ? (
      <Navigate to={notAuthorizedUrl} />
    ) : (
      <NotAuthorizedFallback />
    );
  } else {
    Page = children;
  }

  console.log(...shortLog(`validate page permission is done.`));

  return lazy ? (
    <Suspense fallback={<AuthLoader message="Page loading..." />}>
      {Page}
    </Suspense>
  ) : (
    <>{Page}</>
  );
};

export { ProtectedRoute };
