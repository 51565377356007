// @ts-check

const backgroundStyle = 'background: #202124eb;';
function formatLog({
  scope = 'IDP',
  action = '',
  message = '',
  printTime = false,
  alert = false,
}) {
  const dateTime = printTime ? `⏰ Time: ${new Date().toLocaleString()}` : '';
  const messageStyle = `color: ${
    alert ? '#ff6347' : '#37cc00'
  }; font-weight: bolder;`;

  let actionTemplate = action ? `[${action}]` : '';

  return [
    `%c[${scope}]${actionTemplate} %c${message} %c${dateTime}`,
    `${backgroundStyle} color: #ffff00;`,
    `${backgroundStyle} ${messageStyle}`,
    `${backgroundStyle} color: #7ec4f9;`,
  ];
}

export { formatLog };
