import { FC, useCallback, useEffect, useMemo, useState } from 'react';

// core
import { useNavigate } from 'react-router-dom';
import { DotsLoader } from 'core/components';
import { tlnInstitutionId } from 'core/constants/app-settings';
import { appRouterUrl } from 'core/constants/router-url';
import { useAppDispatch, useAppSelector } from 'core/store';
import { associateUserToTln, refreshUserTlnStatus, setIsTlnUser, setTlnDataSyncConsent, setTlnLoading, setTlnUserId } from 'core/store/slices/userTlnConsentSlice';

const TlnAcknowledge: FC<{}> = () => {
  const [isInitialized, setIsInitialized] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { isTlnUser } = useAppSelector(
    (state) => state.userTlnConsent
  );

  const processTlnCredentials = useCallback(async () => {
    dispatch(setTlnLoading(true));
    let returnedParams = new URLSearchParams(window.location.search);

    const associateTlnUser = async () => {
      if (
        returnedParams.get('error')
      ) {
        dispatch(setTlnLoading(false));

        if (returnedParams.get('error') !== 'consent_denied') {
          dispatch(setTlnDataSyncConsent(false));
          // TODO: Notify user that request was denied
        }

        navigate(appRouterUrl.STUDENT_DASHBOARD);
      }

      if (returnedParams.get('tln_user_id') && returnedParams.get('state')) {
        const tlnUserId = returnedParams.get('tln_user_id') || '';
        dispatch(setTlnUserId(tlnUserId));

        if (!isTlnUser) {
          dispatch(associateUserToTln({
            institutionId: tlnInstitutionId,
          }));
          dispatch(setIsTlnUser(true));
          dispatch(setTlnDataSyncConsent(true));
          dispatch(setTlnLoading(false));
          navigate(appRouterUrl.STUDENT_DASHBOARD);
        }
      }
    }
    associateTlnUser();
  }, [dispatch, navigate, isTlnUser]);

  useEffect(() => {
    if (!isInitialized) {
      dispatch(refreshUserTlnStatus());
      setIsInitialized(true);
    }
  }, [dispatch, isInitialized]);

  useEffect(() => {
    if (isInitialized) {
      processTlnCredentials();
    }
  }, [isInitialized, processTlnCredentials]);

  return (
    <>
      <DotsLoader message='Retrieving TLN Credentials' />
    </>
  );
};

export { TlnAcknowledge };
export default TlnAcknowledge;
