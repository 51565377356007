import { FC } from 'react';
import styled from 'styled-components';

interface ContainerProps {
  size?: `${number}${string}` | `inherit`;
}

const Container = styled.span.attrs<ContainerProps>(({ size }) => ({
  size,
}))<ContainerProps>`
  color: var(--gray-7);

  &[data-disabled='true'] {
    cursor: pointer;
    color: inherit;
  }

  border-radius: inherit;
  width: ${(p) => p.size};
  height: ${(p) => p.size};

  span {
    display: inline-block;
    letter-spacing: -0.65px;
    z-index: -100;
    transition: opacity 0.5s;

    opacity: 0;
    box-shadow: 0px 3px 6px #00000029;
    background-color: #ffffff;
    width: max-content;
  }

  &:not([data-disabled='true']):hover {
    color: var(--gray-7);
    background-color: var(--gray-2);
    box-shadow: 0 0 0 3px var(--gray-2);
  }

  &:hover {
    z-index: 1;
    span:not(:hover) {
      opacity: 1;
    }
  }
`;

type IconTooltipProps = {
  children: string;
  iconClassName: string;
  size?: ContainerProps['size'];
  [x: `data-${string}`]: any;
};

const IconTooltip: FC<IconTooltipProps> = ({
  children,
  iconClassName,
  size = 'inherit',
  ...props
}) => {
  return (
    <Container
      className="relative flex items-center justify-center"
      size={size}
      {...props}
    >
      <i className={iconClassName} />
      <span className="top-100 absolute left-0 p-1">{children}</span>
    </Container>
  );
};

export { IconTooltip };
