import React, { FC } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: block;
  font-weight: 700;

  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #b72a2a;

  & svg {
    color: #b72a2a;
    margin-right: 0.5rem;
  }
`;

type ErrorMessageProps = {
  className?: string;
  children: React.ReactNode;
};

const ErrorMessage: FC<ErrorMessageProps> = ({ className, children }) => {
  return (
    <Container className={className}>
      <span title="Alert" className="fa fa-icon fa-exclamation-triangle" />
      {children}
    </Container>
  );
};

export { ErrorMessage };
