import { FC, forwardRef, useCallback, useEffect, useRef } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { BaseButton } from '../BaseButton';

const Container = styled.dialog`
  box-sizing: content-box;
  padding: 0;

  &:focus {
    box-shadow: unset !important;
  }

  &[open] {
    animation:
      modal-slide-up 500ms forwards,
      modal-fade-in 500ms forwards;
  }

  &[closing] {
    display: block;
    pointer-events: none;
    inset: 0;
    animation: modal-fade-out 500ms forwards;
  }

  &::backdrop {
    background-color: rgb(0 0 0 / 60%);
    opacity: 0;
  }

  &[open]::backdrop {
    animation: modal-fade-in 500ms forwards;
  }

  &[closing]::backdrop {
    animation: modal-fade-out 500ms forwards;
  }
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  padding-inline: 1.5rem;
  padding-block: 2rem;

  &[data-header-style='dark'] {
    background: var(--gray-7);
    color: #ffffff;
  }

  &[data-header-no-padding='true'] {
    padding: 0;
  }

  &[data-show-header-separator='true'] {
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
  }

  &[data-is-empty='true'] {
    display: none;
  }

  &[data-show-sticky-header='true'] {
    position: absolute;
    isolation: isolate;
    z-index: 1;
    right: 0;
  }

  .header-title {
    font-size: 2rem;
    font-weight: bold;
    line-height: 2rem;
    letter-spacing: -1.02px;
    padding-right: 1rem;
  }

  .btn-close {
    color: inherit;
    position: fixed;
    top: 1rem;
    right: 1rem;
    outline: none;
    box-shadow: none;
  }
`;

const Body = styled.section`
  position: relative;
  display: grid;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-inline: 3rem;

  [data-has-header='false'] & {
    height: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  &[data-body-no-padding='true'] {
    padding: 0;
  }

  &[data-body-full-height='true'] {
    height: 100%;
  }
`;

const GlobalStyle = createGlobalStyle`
  /* ISSUE: DIV's beneath "Dialog" element display their scrollbar
            when "Dialog"element is open.
     FIX: Hide any scrollbar when "Dialog" element is open. */
  body:has(dialog[open]) * {
    ::-webkit-scrollbar {
      background-color: transparent !important;
    }
    ::-moz-scrollbar {
      background-color: transparent !important;
    }
  }

  /* stop body scrolling when a Dialog is open */
  body:has(dialog[open]) {
    overflow-y: hidden;
  }

  /* Open/close transitions */
  @keyframes modal-fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes modal-fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes modal-slide-up {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }
`;

export type DialogInstance = {
  element: HTMLDialogElement;
  showModal: HTMLDialogElement['showModal'];
  close: HTMLDialogElement['close'];
};

type ModalRef = React.ForwardedRef<DialogInstance>;

type ModalProps = {
  className?: string;
  children: React.ReactNode;
  title?: string | React.ReactNode;
  headerTheme?: 'dark' | 'light';
  headerStyle?: React.CSSProperties;
  showCloseXButton?: boolean;
  showStickyHeader?: boolean;
  showModal?: boolean;
  width?: React.CSSProperties['width'];
  height?: React.CSSProperties['height'];
  headerNoPadding?: boolean;
  bodyNoPadding?: boolean;
  bodyFullHeight?: boolean;
  onClose?: () => void;
  onCloseAnimationEnd?: () => void;
};

const hasClickedOnBackdrop = (
  e: React.MouseEvent<HTMLDialogElement, MouseEvent>,
) => {
  const dialog = e.currentTarget;
  const dialogDimensions = dialog.getBoundingClientRect();

  if (
    e.clientX < dialogDimensions.left ||
    e.clientX > dialogDimensions.right ||
    e.clientY < dialogDimensions.top ||
    e.clientY > dialogDimensions.bottom
  ) {
    return true;
  }
  return false;
};

const Dialog: FC<ModalProps> = (
  {
    className,
    children,
    showModal = undefined,
    title,
    headerTheme = 'light',
    headerStyle = {},
    showCloseXButton = true,
    width = 'fit-content',
    height = 'fit-content',
    headerNoPadding = false,
    bodyNoPadding = false,
    showStickyHeader = false,
    bodyFullHeight = false,
    onClose = () => null,
    onCloseAnimationEnd = () => null,
  },
  forwardRef: ModalRef,
) => {
  const dialogRef = useRef<HTMLDialogElement | null>();

  const openModal = () => {
    const dialog = dialogRef.current;
    if (!dialog) return;

    // You can't open the same dialog twice.
    if (!dialog?.open) {
      dialogRef.current?.showModal();
    }
  };

  const closeModal = useCallback(() => {
    const dialog = dialogRef.current;
    if (!dialog) return;

    dialog.setAttribute('closing', 'true');
    dialog.addEventListener(
      'animationend',
      () => {
        dialog.removeAttribute('closing');
        dialog.close();
        onCloseAnimationEnd();
      },
      {
        once: true,
      },
    );

    onClose();
  }, [onClose, onCloseAnimationEnd]);

  useEffect(() => {
    if (showModal === undefined || forwardRef) return;

    const modal = dialogRef.current;
    if (showModal) {
      !modal?.open && modal?.showModal();
    } else {
      modal?.open && closeModal();
    }
  }, [forwardRef, showModal, closeModal]);

  return (
    <Container
      autoFocus
      data-has-header={title || showCloseXButton}
      className={className}
      style={{
        width,
        height,
      }}
      ref={(node) => {
        dialogRef.current = node;

        let instance = node
          ? ({
              showModal: openModal,
              close: closeModal,
              element: node,
            } satisfies DialogInstance)
          : null;

        if (typeof forwardRef === 'function') {
          forwardRef(instance);
        } else if (forwardRef) {
          forwardRef.current = instance;
        }
      }}
      onMouseDown={(e) => {
        if ((e.target as HTMLElement).nodeName !== 'DIALOG') {
          dialogRef.current?.setAttribute('data-mouse-down', 'true');
        }
      }}
      onMouseUp={() => {
        setTimeout(() => dialogRef.current?.removeAttribute('data-mouse-down'));
      }}
      onClick={(e) => {
        if (
          dialogRef.current?.getAttribute('data-mouse-down') ||
          ['INPUT', 'BUTTON'].includes((e.target as HTMLElement).nodeName)
        )
          return;

        if (hasClickedOnBackdrop(e)) {
          closeModal();
        }
      }}
      onClose={() => {
        if (!forwardRef) {
          onClose?.();
        }
      }}
    >
      <GlobalStyle />
      <Header
        data-modal-header
        data-is-empty={!title && !showCloseXButton}
        data-show-sticky-header={showStickyHeader}
        data-header-no-padding={headerNoPadding}
        data-header-style={headerTheme}
        style={headerStyle}
      >
        {title && <div className="header-title">{title}</div>}
        {showCloseXButton && (
          <BaseButton onClick={closeModal} className="btn-close">
            <i className="fa-solid fa-xmark" />
          </BaseButton>
        )}
      </Header>

      <Body
        data-modal-body
        data-body-full-height={bodyFullHeight}
        data-body-no-padding={bodyNoPadding}
      >
        {children}
      </Body>
    </Container>
  );
};

const ModalWithRef = forwardRef((props: ModalProps, ref: ModalRef) =>
  Dialog(props, ref),
);

export { ModalWithRef as Modal };
