export type OSNames =
  | 'Unknown'
  | 'Windows'
  | 'MacOS'
  | 'Linux'
  | 'iOS'
  | 'Android';

function getOperativeSystemName(): OSNames {
  let osName: OSNames = 'Unknown';

  const userAgent = navigator.userAgent;

  if (/win/i.test(userAgent)) {
    osName = 'Windows';
  } else if (/mac/i.test(userAgent)) {
    osName = 'MacOS';
  } else if (/linux/i.test(userAgent)) {
    osName = 'Linux';
  } else if (/iphone|ipad|ipod/i.test(userAgent)) {
    osName = 'iOS';
  } else if (/android/i.test(userAgent)) {
    osName = 'Android';
  }

  return osName;
}

export { getOperativeSystemName };
