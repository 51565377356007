import styled from 'styled-components';

interface BtnProps {}

const BaseButton = styled.button.attrs<BtnProps>(() => ({
  type: 'button',
}))<BtnProps>`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  border: none;
  background: none;
  padding: 0;

  font-size: inherit;
  font-family: inherit;
  height: fit-content;

  transition: all 30ms ease-in-out;

  &:not(:disabled) {
    cursor: pointer;

    &:hover,
    &:hover > * {
      transform: scale(1.05);
    }
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export { BaseButton };
