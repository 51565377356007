import * as d3 from 'd3';

export const animationDuration = 600;
export const hideAnimationDuration = 400;
export const delayBetweenBarAnimation = 0;
export const barDelay = (_, i = 0) => i * delayBetweenBarAnimation;

/**
 * @export
 * @param {D3AnyElementSelection} selection
 * @param {number} [duration=animationDuration]
 */
export function transitionText(selection, duration = animationDuration) {
  selection
    .style('opacity', '0')
    .transition()
    .duration(duration)
    .style('opacity', '1');
}

/**
 * @export
 * @param {D3AnyElementSelection} selection
 * @param {number} [duration=animationDuration]
 */
export function transitionHideText(
  selection,
  duration = hideAnimationDuration,
) {
  selection.transition().duration(duration).style('opacity', '0');
}

const makeId = () => Math.floor(Math.random() * 1000 + 1);
const D3_ID = makeId();
/**
 * Utility to remove the tooltip element once the component is unmounted
 * @param {{
 *  svgElement: SVGSVGElement | null
 * }} param
 */
export function removeChartElements({ svgElement }) {
  // remove tooltip
  const elements = document.querySelectorAll(`.d3-tooltip-${D3_ID}`);
  elements?.forEach((element) => element.remove());
  // remove svg content
  while (svgElement?.firstChild) {
    svgElement.firstChild.remove();
  }
}

/**
 * @export
 * @param {{
 *   svg: D3AnyElementSelection,
 *   dataGroup: D3AnyElementSelection,
 *   appendToBody?: boolean,
 *   createTooltipText: (...rest) => string,
 * }} param
 */
export function createTooltip({
  svg,
  dataGroup,
  appendToBody = false,
  createTooltipText = (_rest) => '',
}) {
  const TOOLTIP_CONTAINER_ID = `d3-chart-tooltip-container-${makeId()}`;
  let container;

  if (appendToBody) {
    const body = d3.select('body');
    body.style('position', 'relative');
    container = body.append('svg');
  } else {
    container = svg.append('g');
  }

  const tooltip = container
    .attr('id', TOOLTIP_CONTAINER_ID)
    .attr('class', `d3-tooltip-${D3_ID} tooltip`)
    .attr('font-size', '12px')
    .style('display', 'none')
    .style('top', 0)
    .style('left', 0);

  const tooltipBackground = tooltip
    .append('foreignObject')
    .style('overflow', 'visible')
    .attr('width', '100%');

  const tooltipText = tooltipBackground
    .append('xhtml:div')
    .style('background', 'black')
    .style('color', 'white')
    .style('opacity', 0.9)
    .style('font-size', '12px')
    .style('font-weight', 'bold')
    .style('max-width', 'fit-content')
    .style('padding', '0.5em')
    .style('pointer-events', 'none');

  dataGroup
    .on('mouseleave mouseout wheel', () => {
      tooltip.style('display', 'none').style('opacity', 0);
      // alert()
    })
    .on('mousemove', (event, d, index) => {
      // tooltip display
      tooltip
        .style('display', null)
        .transition()
        .duration(800)
        .style('opacity', 1);
      // tooltip position
      let xPosition;
      let yPosition;

      if (appendToBody) {
        xPosition = event.pageX;
        yPosition = event.pageY;
      } else {
        xPosition = event.offsetX || event.layerX || event.clientX;
        yPosition = event.offsetY || event.layerY || event.clientY;
      }

      const offset = 5;
      tooltip.attr(
        'transform',
        'translate(' + (xPosition + offset) + ',' + (yPosition + offset) + ')',
      );

      // tooltip text
      const text = createTooltipText({ event, d, index });
      tooltipText.html(text);
    });
}
