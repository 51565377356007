import React, { FC } from 'react';

type OutputFieldProps = GenericFieldProps;

const OutputField: FC<OutputFieldProps> = ({ label, value, defaultValue }) => {
  return (
    <div className="form-group">
      <label className="mb-0 font-bold">{label}</label>
      <div>{value || defaultValue}</div>
    </div>
  );
};

export { OutputField };
