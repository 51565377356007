import { createContext, FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from 'core/auth';
import { RoleSwitch } from 'core/components/RoleSwitch';
import { startPageByRole } from 'core/constants/router-url';
import { getIDPClaimToken } from 'core/services/user-service';
import {
  useAppDispatch,
  useAppSelector,
  useCurrentRoleSelector,
} from 'core/store';
import { setShowNavbar } from 'core/store/slices';
import {
  getStudentProfileAsync,
  setStudentProfileCached,
} from 'core/store/slices/studentProfileSlice';
import {
  resumeUser,
  setPrimaryRole,
  setUserEmplid,
  setUserRoles,
  setViewAsRole,
} from 'core/store/slices/userProfileSlice';

const initialState = {};
const AppContext = createContext(initialState);

type AppProviderProps = {
  children: React.ReactElement;
};

const AppProvider: FC<AppProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    state: { userRoles },
  } = useAuthContext();
  const { isAuthenticated } = useAppSelector(
    (state) => state.auth,
  );

  useEffect(() => {
    const claim = getIDPClaimToken();
    dispatch(setUserEmplid(claim?.emplid || ''));
    dispatch(setUserRoles(userRoles?.roles || []));
    dispatch(setPrimaryRole(userRoles?.roles || []));
  }, [dispatch, userRoles?.roles]);

  useEffect(() => {
    const claim = getIDPClaimToken();

    if (isAuthenticated && userRoles?.completed && claim?.emplid) {
      dispatch(getStudentProfileAsync());
    }
  }, [dispatch, isAuthenticated, userRoles?.completed]);

  useEffect(() => {
    const leavePage = () => {
      dispatch(resumeUser());
    };

    window.addEventListener('beforeunload', leavePage);

    return () => {
      window.removeEventListener('beforeunload', leavePage);
    };
  }, [dispatch]);

  const currentRole = useCurrentRoleSelector();
  const viewAs = useAppSelector((state) => state.userProfile.viewAs);
  const viewAsActive = viewAs.active;
  const profileRoles = viewAs.selectedUser?.roles;
  const resumePage = viewAs.startPage || '';
  const preferredName = viewAs.selectedUser?.profile.preferredName || '';

  return (
    <AppContext.Provider value={{}}>
      {children}

      {viewAsActive && currentRole && (
        <RoleSwitch
          userName={preferredName}
          currentRole={currentRole}
          roles={profileRoles}
          onRoleChange={(roleName) => {
            dispatch(setViewAsRole(roleName));
            navigate(startPageByRole[roleName]);
          }}
          onResumeUser={() => {
            dispatch(resumeUser());
            dispatch(setStudentProfileCached(false));
            dispatch(getStudentProfileAsync());
            dispatch(setShowNavbar(true));
            navigate(resumePage);
          }}
        />
      )}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
