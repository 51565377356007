// @ts-check

const AUTH_LOCAL_STORAGE_KEY = 'asu.app.auth';

/**
 * @param {AuthState} state
 */
function storeState(state) {
  localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, JSON.stringify(state));
}

function recoverState() {
  const rawState = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY) || '';
  const isValidJson = rawState.startsWith('{') && rawState.endsWith('}');

  const localState = /** @type {AuthState} */ (
    isValidJson ? JSON.parse(rawState) : {}
  );

  return localState;
}

function clearState() {
  localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
}

export {
    storeState,
    recoverState,
    clearState,
};
