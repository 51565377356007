const tZones: string[] = [
  'Atlantic (UTC-04:00)',
  'Eastern (UTC-05:00)',
  'Central (UTC-06:00)',
  'Mountain (UTC-07:00)',
  'Pacific (UTC-08:00)',
  'Alaska (UTC-09:00)',
  'Hawaii-Aleutian (UTC-10:00)',
  'American Samoa (UTC-11:00)',
  'Chamorro (UTC+10:00)',
];

const timeZones = tZones.map((zoneName) => ({
  label: zoneName,
  value: zoneName,
}));

export { timeZones };
