import React, { FC } from 'react';

import styled from 'styled-components';

const Fieldset = styled.fieldset`
  .form-check-label {
    font-weight: normal !important;
  }

  .form-check:first-of-type {
    margin-top: 0;
  }
`;

type OptionListFieldProps = GenericFieldProps & {
  list: ListItem[];
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
};

function makeId(id: string) {
  return `option-item-${id}`;
}

const OptionListField: FC<OptionListFieldProps> = ({
  label,
  value,
  list,
  error,
  required,
  onChange,
}) => {
  const name = 'option-select-' + label.replaceAll(' ', '-');

  return (
    <Fieldset>
      <legend>
        <strong>{label}</strong>
      </legend>
      {error && (
        <small className="invalid-feedback is-invalid">
          <span title="Alert" className="fa fa-icon fa-exclamation-triangle" />
          {error}
        </small>
      )}

      {list.map((item) => (
        <div key={makeId(item.id)} className="form-check">
          <input
            required={required}
            className="form-check-input"
            type="radio"
            name={name}
            id={makeId(item.id)}
            value={item.value ?? item.text}
            checked={item.value === value || item.text === value}
            onChange={onChange}
          />
          <label className="form-check-label" htmlFor={makeId(item.id)}>
            {item.text}
          </label>
        </div>
      ))}
    </Fieldset>
  );
};

export { OptionListField };
