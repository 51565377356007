import { FC } from 'react';

import partnerConfig from '../../../partner-config.json';

const BrandingOverrideProvider: FC = () => {
  const stylesStr = Object.entries(
    (partnerConfig as any)?.style_overrides || {}
  ).map((styleDeclaration) => `${styleDeclaration[0]}: ${styleDeclaration[1]}`);
  const styleBlock = `:host, :root { ${stylesStr.join(';')} }`

  return (
    <>
      {stylesStr?.length && (
        <style>
          {styleBlock}
        </style>
      )}
    </>
  );
};

export { BrandingOverrideProvider };
