import { FC } from 'react';
import styled from 'styled-components';

const Container = styled.article`
  h4 {
    margin-block: 0;
    font-size: 1rem;
  }
  ul {
    padding: 0;
    list-style: none;
  }
`;

type BlockListProps = {
  title: string;
  items: string[];
};

const BlockList: FC<BlockListProps> = ({ title, items }) => {
  return (
    <Container>
      <h4>{title}</h4>
      {items?.length > 0 ? (
        <ul>{items?.map((item, index) => <li key={index}>{item}</li>)}</ul>
      ) : (
        <p>None</p>
      )}
    </Container>
  );
};

export { BlockList };
