// @ts-check
import * as d3 from 'd3';
import {
  animationDuration,
  barDelay,
  createTooltip,
  transitionHideText,
  transitionText,
} from './d3-helper';

/**
 * @param {StackedBarChartType & {svgElement: SVGSVGElement}} param
 */
function createStackedBarChart({
  svgElement,
  dataset,
  width = 300,
  height = 300,
  barWidth,
  areaColor1,
  areaColor2,
  areaTextColor1 = '#000',
  areaTextColor2 = '#000',
  areaTitle1 = '',
  areaTitle2 = '',
  appendTooltipToBody = false,
  showTooltip = true,
  showLegend = true,
  showAxisX = true,
  showAxisY = true,
  showInternalBarValue = true,
  showInternalBarValueOnMouseHover = false,
  marginChart,
  formatData = (data) => Math.round(data),
}) {
  // ==========================================================
  // Dataset
  // ==========================================================
  const areaColors = [areaColor1, areaColor2];
  const areaTitles = [areaTitle1, areaTitle2];
  const areaKeys = Object.keys(dataset[0]).filter(
    (key) => /** @type { StackedBarChartKeys } */ (key) !== 'domainName',
  );

  const MAX_HEIGHT = Math.max(...dataset.map((d) => d.area1 + d.area2));
  const granTotal = dataset.reduce(
    (acc, data) => acc + data.area1 + data.area2,
    0,
  );

  // ==========================================================
  // SVG
  // ==========================================================
  const svg = d3.select(svgElement);
  svg.attr('width', width);
  svg.attr('height', height);

  svg.attr(
    'style',
    `--area-text-color-1:${areaTextColor1}; ` +
      `--area-text-color-2: ${areaTextColor2}`,
  );

  // ==========================================================
  // Render Data
  // ==========================================================
  // Layout
  const margin = {
    top: marginChart?.top || 0,
    right: marginChart?.right || 0,
    bottom: marginChart?.bottom || 0,
    left: marginChart?.left || 0,
  };

  const xWidth = width - margin.left - margin.right;
  const yHeight = height - margin.top - margin.bottom;

  const axisGroup = svg
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`);
  // ==========================================================
  // Axises domains
  // ==========================================================
  const xScale = d3
    .scaleBand()
    .rangeRound([0, xWidth])
    .paddingInner(0.1)
    .paddingOuter(0)
    .align(0.1)
    .domain(dataset.map((d) => d.domainName));

  const yScale = d3
    .scaleLinear()
    .rangeRound([yHeight, 0])
    .domain([0, MAX_HEIGHT])
    .nice();

  const colorScale = d3.scaleOrdinal().range(areaColors).domain(areaKeys);
  // ==========================================================
  // create stacked bar
  // ==========================================================
  // create a new dataset as source of the stacked bars
  // @ts-ignore
  const dataBarStack = d3.stack().keys(areaKeys)(dataset);

  /**
   * @param {any} data
   * @return {number}
   */
  const getXScale = (data) => {
    const d = /** @type {StackedBarChartData} */ (data);
    return xScale(d.domainName) || 0;
  };

  // group container
  const barGroup = axisGroup
    .selectAll('g')
    .data(dataBarStack)
    .join('g')
    .attr('class', (d, i) => `y-axis-${i}`)
    .attr('fill', (d) => colorScale(d.key));
  // Stacked Bars
  barGroup
    .selectAll('rect')
    .data((d) => d)
    .join('rect')
    .attr('class', 'bar')
    .attr('data-index', (_d, index) => index)
    .attr('width', barWidth || xScale.bandwidth())
    .attr('x', (d) => getXScale(d.data))
    .attr('y', yHeight)
    .transition()
    .duration(animationDuration)
    .delay(barDelay)
    .attr('height', (dRanges) => yScale(dRanges[0]) - yScale(dRanges[1]))
    .attr('y', (dRanges) => yScale(dRanges[1]))
    .end()
    .then(() => {
      // =================================================================
      // This will triggered when all previous transactions will be finished
      // =================================================================
      // Internal bar values
      // ================================================================
      if (showInternalBarValue) {
        const barValueGroup = barGroup
          .selectAll('text')
          .data((d) => d)
          .join('text')
          .text((d) => d[1] - d[0])
          .style('pointer-events', 'none')
          .attr('text-anchor', 'middle')
          .attr('x', (d) => getXScale(d.data) + xScale.bandwidth() / 2)
          .attr(
            'y',
            (d) => yScale(d[0]) + (yScale(d[1]) - yScale(d[0])) / 2 + 6,
          );

        if (showInternalBarValueOnMouseHover) {
          barValueGroup.style('opacity', 0);

          barGroup
            .on('mouseover', () => {
              barValueGroup.call(transitionText);
            })
            .on('mouseout', () => {
              barValueGroup.call(transitionHideText);
            });
        } else {
          barValueGroup.call(transitionText);
        }
      }

      // =================================================================
      // Top bar values (the sum of areas)
      // =================================================================
      barGroup
        .selectAll('.y-axis-1')
        .data((d) => d)
        .join('text')
        .attr('class', 'top-bar-text')
        .attr('x', (d) => getXScale(d.data) + xScale.bandwidth() / 2)
        .attr('y', (d) => yScale(d[1]) - 10)
        .text((d) => {
          const sum = d.data.area1 + d.data.area2;
          const percentage = formatData((100 / granTotal) * sum);
          const total = `${sum} (${percentage}%)`;

          return total;
        })
        .attr('text-anchor', 'middle')
        .call(transitionText);
    });
  // =================================================================
  // create X axis
  // =================================================================
  const xAxisGroup = axisGroup
    .append('g')
    .attr('class', 'x-axis')
    .attr('transform', 'translate(0,' + yHeight + ')')
    .call(d3.axisBottom(xScale));

  if (!showAxisX) {
    xAxisGroup.select('.domain').style('display', 'none');
    xAxisGroup.selectAll('.tick line').style('display', 'none');
  }

  xAxisGroup.selectAll('text').text('');
  xAxisGroup
    .selectAll('.tick')
    .append('foreignObject')
    .call(transitionText)
    .style('overflow', 'visible')
    .style('transform', `translateX(calc(-${xScale.bandwidth() / 2}px))`)
    .style('width', xScale.bandwidth())
    .append('xhtml:div')
    .text((dataText) => dataText)
    .style('font-weight', 'bold')
    .style('font-size', '0.93rem')
    .style('text-align', 'center')
    .style('width', 'min-content')
    .style('min-width', 'fit-content')
    .style('margin', '0 auto');
  // =================================================================
  // create Y axis
  // =================================================================
  showAxisY &&
    axisGroup
      .append('g')
      .attr('class', 'y-axis')
      .call(d3.axisLeft(yScale).ticks(undefined))
      .append('text')
      .attr('x', 2)
      .attr('y', yScale(yScale.ticks().pop() || 0) + 0.5)
      .attr('dy', '0.32em')
      .attr('font-weight', 'bold')
      .attr('text-anchor', 'start');
  // =================================================================
  // create legend and tooltip
  // =================================================================
  showLegend && createLegend({ axisGroup, colorScale, areaTitles, xWidth });
  showTooltip &&
    createTooltip({
      svg,
      dataGroup: barGroup,
      appendToBody: appendTooltipToBody,
      createTooltipText: ({ d }) => areaTitles[d.index],
    });

  return svg;
}

/**
 * @param {{
 *   axisGroup: D3GroupElementSelection,
 *   colorScale: D3ScaleOrdinal,
 *   areaTitles: string [],
 *   xWidth: number
 * }} param
 */
function createLegend({ axisGroup, colorScale, areaTitles, xWidth }) {
  const legend = axisGroup
    .append('g')
    .attr('class', 'legend-group')
    .attr('font-size', 10)
    .selectAll('g')
    .data(areaTitles.slice().reverse())
    .join('g')
    .attr('transform', (d = '', i = 0) => `translate(0,${i * 22})`);

  legend
    .append('circle')
    .attr('cx', xWidth - 60)
    .attr('r', 10)
    .attr('fill', colorScale);

  legend
    .append('text')
    .attr('x', xWidth - 45)
    .attr('y', 2)
    .attr('dy', '0.32em')
    .text((d = '') => d);
}

export { createStackedBarChart };
