import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { CloseSVG } from '../Icons';

const Container = styled.div`
  position: relative;
  isolation: isolate;

  input {
    display: inline-block;
    width: auto;
    /* clears the ‘X’ from Internet Explorer */
    &::-ms-clear {
      display: none;
      width: 0;
      height: 0;
    }
    &::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }
    /* clears the ‘X’ from Chrome */
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }
  }

  button {
    display: none;
    &[data-visible='true'] {
      display: inline-block;
      width: auto;
    }
    position: absolute;
    appearance: unset;
    background: transparent;
    border: unset;
    padding: 0px;
    margin-left: -26px;
    line-height: 1;
    transform: translateY(-50%);
    top: 50%;
  }
`;

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  clearButtonAriaLabel: string;
  onClear: () => void;
};

const Input = forwardRef<HTMLInputElement, Props>(
  ({ value, clearButtonAriaLabel, onClear, ...props }, ref) => {
    const clearButtonVisible = !!value;
    return (
      <Container>
        <input ref={ref} value={value} {...props} />
        <button
          className="text-gray-5"
          onClick={onClear}
          aria-label={clearButtonAriaLabel}
          data-visible={clearButtonVisible}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onClear();
            }
          }}
        >
          <CloseSVG tabIndex={-1} />
        </button>
      </Container>
    );
  },
);

export { Input };
