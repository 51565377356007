import React, { FC } from 'react';
import ReactSelect, { ActionMeta, components } from 'react-select';
import { createGlobalStyle } from 'styled-components';

import { generateId } from 'core/utils/generate-id';
import { dropdownStyles } from './dropdown-config';

const GlobalStyle = createGlobalStyle`
  .menu {
    animation: fadeIn 0.2s ease-in-out;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(2rem);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

type DropdownProps = Omit<import('react-select').Props, 'onChange'> & {
  onChange:
    | ((newValue: OptionItem, actionMeta: ActionMeta<unknown>) => void)
    | undefined;
  options: import('react-select').Props['options'];
};

const Dropdown: FC<DropdownProps> = ({
  id,
  className = '',
  onChange,
  value,
  ...props
}) => {
  const dropdownId = id || generateId('dropdown-');

  const valueItem =
    typeof value === 'string'
      ? props.options?.find((v) => (v as OptionItem).value === value)
      : value;

  return (
    <>
      <GlobalStyle />
      <ReactSelect
        id={dropdownId}
        value={valueItem}
        className={`react-select ${className}`}
        isClearable
        components={{
          IndicatorSeparator: (props) => (
            <components.IndicatorSeparator
              {...props}
              className="react-select-indicator-separator"
            />
          ),
          IndicatorsContainer: (props) => (
            <components.IndicatorsContainer
              {...props}
              className="react-select-indicators-container"
            />
          ),
          SingleValue: (props) => (
            <components.SingleValue
              {...props}
              className="react-select-single-value"
            />
          ),
          Placeholder: (props) => (
            <components.Placeholder
              {...props}
              className="react-select-place-holder"
            />
          ),
          Control: (props) => (
            <components.Control {...props} className="react-select-control" />
          ),
          Input: (props) => (
            <components.Input
              {...props}
              className="react-select-input custom-input"
            />
          ),
          Menu: (props) => <components.Menu {...props} className="menu" />,
          ...props.components,
        }}
        styles={dropdownStyles}
        classNames={{
          option: (state) => {
            if (state.isDisabled) return `disabled`;
            if (state.isFocused) return `focused`;
            return '';
          },
        }}
        onChange={(newValue, actionMeta) => {
          let item = newValue;

          if (actionMeta.action === 'clear') {
            item = {
              value: null,
            };
          }

          onChange?.(item as OptionItem, actionMeta);
        }}
        {...props}
      />
    </>
  );
};

export { Dropdown };
