import { FC } from 'react';
import styled from 'styled-components';

const Container = styled.label`
  cursor: pointer;

  .fa-star {
    position: absolute;
    left: 0;
  }

  .checked-icon {
    opacity: 0;
  }
  .not-checked-icon {
    opacity: 1;
  }

  &:hover,
  &[data-checked='true'] {
    color: var(--primary);

    .checked-icon {
      opacity: 1;
    }
    .not-checked-icon {
      opacity: 0;
    }
  }
`;

type StarToggleProps = {
  className?: string;
  checked: boolean;
  ariaLabel: string;
  onClick?: () => void;
  onMouseOver?: React.MouseEventHandler<HTMLLabelElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLLabelElement>;
};

const StarToggle: FC<StarToggleProps> = ({
  className,
  checked,
  ariaLabel = 'toggle',
  onClick,
  onMouseOver,
  onMouseLeave,
}) => {
  return (
    <Container
      tabIndex={0}
      role="switch"
      className={`${className} text-gray-5 relative m-0 w-4 p-0`}
      data-checked={checked}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === 'Space') {
          e.stopPropagation();
          onClick?.();
        }
      }}
    >
      <input
        type="checkbox"
        className="absolute opacity-0"
        aria-label={ariaLabel}
        onClick={onClick}
      />
      <i className="fa-regular fa-star not-checked-icon " />
      <i className="fa-solid fa-star checked-icon" />
    </Container>
  );
};

export { StarToggle };
