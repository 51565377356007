import { RoleName } from './app-constants';

const appRouterUrl = {
  HOME: '/',
  STUDENT_PROFILE: '/student-profile',
  STUDENT_DASHBOARD: '/student-dashboard',
  PLANS_LIST: '/plan-list',
  PLAN_DETAIL: '/plan-detail',
  PLAN_DETAIL_BY_PLAN_ID: '/plan-detail/:planId',
  PLAN_SET_UP: '/plan-setup',
  COMPARISON_LIST: '/comparison-list',
  NOT_AUTHORIZED: '/401-user-not-authorized',
  NOT_FOUND: '/404-resource-not-found',
  LOG_IN: '/oauth2/callback',
  LOG_OUT: '/logout',
  TLN_REQUEST: '/tln/request',
  TLN_RESPONSE: '/tln/acknowledge',
  ADVISOR_DASHBOARD: '/advisor-dashboard',
  COACH_DASHBOARD: '/coach-dashboard',
  ADMIN_PROFILE_VIEW: '/admin-profile-view',
  ADMIN_DASHBOARD: '/admin-dashboard',
  ADMIN_SETTINGS: '/admin-settings',
  ADMIN_REPORTS: '/admin-reports',
} as const;

export type RouterUrlValue = (typeof appRouterUrl)[keyof typeof appRouterUrl] &
  string;

const profileRouterUrl = {
  CONTACT_INFO: 'contact-info',
  DEGREE_PROGRAMS: 'degree-programs',
  ADVISOR_INFO: 'advisor-info',
} as const;

const startPageByRole: Record<string, string> = {
  [RoleName.ADMIN]: appRouterUrl.ADMIN_DASHBOARD,
  [RoleName.ADVISOR]: appRouterUrl.ADVISOR_DASHBOARD,
  [RoleName.COACH]: appRouterUrl.ADVISOR_DASHBOARD,
  [RoleName.STUDENT]: appRouterUrl.STUDENT_DASHBOARD,
  undefined: appRouterUrl.NOT_FOUND,
  null: appRouterUrl.NOT_FOUND,
};

export { appRouterUrl, profileRouterUrl, startPageByRole };
